import GmailIcon from "images/gmail_logo.png";
import DropDownArrow from "images/tour/drop_down_arrow.svg";

const Slide2 = () => {
    return (
        <div className="sm:flex bg-white rounded-l-[8px] overflow-y-hidden  sm:h-[340px] h-[260px]">
            {/* Left Sidebar Containing Gmail Icon */}
            <div className="bg-white-100 w-2/8  pt-4 px-4 sm:px-6">
                <div className="flex items-cente mb-4">
                    <img src={GmailIcon} alt="Gmail Logo" className="w-10 h-8" />
                </div>
            </div>
            {/* Right Sidebar Containing Email Body */}
            <div className="flex-1 sm:pl-2 pl-0  ml-4 sm:ml-0">
                {/* Email Name */}
                <div className="mb-4 pt-1 sm:pt-4 ">
                    <div className="flex">
                        <div className="font-medium text-black">EmailZap</div>
                        <div className="text-sm text-gray-500 mt-1 ml-1">&#60;noreply@emailzap.execfn.com&#62;</div>
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                        to me
                        <img src={DropDownArrow} alt="Gmail Logo" className="w-3 h-3 ml-1 " />
                    </div>
                </div>
                {/* Email Body Design */}
                <div className="border border-gray-150 border-b-0 rounded bg-white ">
                    <div className="bg-[#F9FAFB] flex text-[#6B7280]">
                        <div className="flex-1 py-2 pl-6 ">Sender name</div>
                        <div className="flex-1 py-2 ">Subject</div>
                    </div>
                    {[...Array(6)].map((e, index) => (
                        <div className="flex mt-3" key={index}>
                            <div className="flex-1">
                                <div className="w-2/4 bg-[#E1E5EB] ml-5" style={{ height: "0.3rem" }}></div>
                            </div>
                            <div className="flex-1 ml-3">
                                <div className="w-3/4 bg-[#E1E5EB] mb-1" style={{ height: "0.3rem" }}></div>
                                <div className="w-1/4 bg-[#E1E5EB] mb-3" style={{ height: "0.3rem" }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Slide2;
