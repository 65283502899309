import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Tooltip from "@mui/material/Tooltip";
import { useIsMailBotActive } from "hooks/api/mailbot";
import { useState } from "react";

export default function EmailZapInfoCard() {
    const [showInfoOnMobile, setShowInfoOnMobile] = useState(false);
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    if (mailBotEnabledPending) {
        return null;
    }
    return (
        <div
            className="w-[89.33vw] md:w-[37.5vw] pb-2 rounded-[12px] md:flex md:flex-col md:justify-around"
            style={{
                background: "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)",
                border: "1px solid rgba(235, 235, 243, 1)",
            }}
        >
            <p className="font-[Inter] text-[12px] md:text-[16px] text-[#202224] font-medium ml-[3.2vw] mt-[1.16vh] md:ml-[1.11vw] md:mt-[1.85vh] opacity-70 leading-4 md:leading-5">
                E-mail Zap Autocleaner
            </p>
            <div className="flex flex-row items-center justify-between">
                <p
                    className="font-[Inter] text-[12px] md:text-[16px] font-[500] ml-[3.2vw] mt-[1.16vh] md:ml-[1.11vw] mt-[1.11vh] leading-5"
                    style={{
                        color: mailBotEnabled ? "#00B69B" : "#FF5C00",
                    }}
                >
                    {mailBotEnabled ? "Enabled" : "Disabled"}
                </p>
                <div className="md:hidden mr-[3.2vw]">
                    <Tooltip
                        title={
                            mailBotEnabled
                                ? "Emailzap's auto-cleaner is live, filtering all incoming emails to ensure only relevant ones reach your inbox."
                                : "Emailzap's auto-cleaner is not live and wont be able to process any emails at the moment."
                        }
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        placement="bottom-start"
                    >
                        <InformationCircleIcon
                            width={17}
                            height={17}
                            onClick={() => setShowInfoOnMobile(!showInfoOnMobile)}
                        />
                    </Tooltip>
                </div>
            </div>
            <p className="text-[#81868F] font-[Inter] mt-[1.56vh] ml-[1.11vw] hidden md:block leading-4 text-[12px]">
                {mailBotEnabled
                    ? "Emailzap's auto-cleaner is live, filtering all incoming emails to ensure only relevant ones reach your inbox."
                    : "Emailzap's auto-cleaner is not live and wont be able to process any emails at the moment."}
            </p>
        </div>
    );
}
