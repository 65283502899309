import { Dialog, Transition } from "@headlessui/react";
import { useCancelSubscription } from "hooks/api/payments";
import React, { Fragment, useState } from "react";
import Loader from "toolkit/Loader";
import { ISubscription } from "types/maintypes";

interface CancellationProps {
    subscription: ISubscription;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setShowToast: any;
    setToastVariant: any;
    setToastHeading: any;
    setToastText: any;
}

enum CancellationReason {
    TOO_EXPENSIVE = "Too expensive",
    DIDNT_WORK = "It didn't work as expected",
    DELETE_EMAILS = "I was looking to delete emails",
    NO_BLOCKING = "I didn't want emailzap blocking anyone on their own",
    CONFUSING = "It was confusing",
    NEED_FEATURES = "Need more features",
    OTHER = "Other",
}

// We want to show cancellation reasons in random order to avoid bias in selection, but always show "OTHER" at the end
const randomCancellationReasons = Object.values(CancellationReason).sort((a, b) => {
    if (a === CancellationReason.OTHER) {
        return 1;
    }
    if (b === CancellationReason.OTHER) {
        return -1;
    }
    return Math.random() - 0.5;
});

const Cancellation: React.FC<CancellationProps> = ({
    subscription,
    open,
    setOpen,
    setShowToast,
    setToastVariant,
    setToastHeading,
    setToastText,
}) => {
    const cancelSubscription = useCancelSubscription();
    const [cancellationReason, setCancellationReason] = useState<string[]>([]);
    const [cancelationText, setCancelationText] = useState<string>("");
    const handleCancelSubscription = () => {
        setOpen(false);
        cancelSubscription.mutate(
            {
                id: subscription.id,
                reason: cancellationReason,
                reasonText: cancelationText,
            },
            {
                onSuccess: () => {
                    // Show success message
                    setToastHeading("Subscription cancelled!");
                    setToastText("Your subscription was successfully cancelled.");
                    setToastVariant("success");
                    setShowToast(true);
                    setCancellationReason([]);
                    setCancelationText("");
                },
                onError: () => {
                    // Show error message
                    setToastHeading("Subscription cancellation failed!");
                    setToastText("Error occured while cancelling your subscription. Please try again.");
                    setToastVariant("failure");
                    setShowToast(true);
                    setCancellationReason([]);
                    setCancelationText("");
                },
            },
        );
    };

    if (cancelSubscription.isPending) {
        return <Loader />;
    }
    const isTextReasonRequired =
        cancellationReason.filter(
            (x) =>
                x === CancellationReason.OTHER ||
                x === CancellationReason.NEED_FEATURES ||
                x === CancellationReason.DIDNT_WORK,
        ).length > 0;
    const allowCancelation = cancellationReason.length > 0 && (!isTextReasonRequired || cancelationText.length > 0);
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 bg-gradient-to-b	from-white to-indigo-100">
                                <div>
                                    <div className="mt-3 text-left sm:mt-5">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-xl font-bold leading-6 text-gray-900 pl-8 pb-4 border-indigo-600 border-b-8"
                                        >
                                            Cancel subscription
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            <p className="text-sm text-gray-700">
                                                We are really sad to see you cancel you subscription. We hope to see you
                                                back later.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Collect cancelation reason */}
                                <div>
                                    {randomCancellationReasons.map((reason) => (
                                        <div key={reason} className="mt-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                                    name="reason"
                                                    value={reason}
                                                    checked={cancellationReason.includes(reason)}
                                                    onChange={(e) =>
                                                        setCancellationReason((prev) => {
                                                            if (e.target.checked) {
                                                                return [...prev, reason];
                                                            }
                                                            return prev.filter((x) => x !== reason);
                                                        })
                                                    }
                                                />
                                                <span className="ml-2">{reason}</span>
                                            </label>
                                        </div>
                                    ))}
                                    {/* If reason is other or need more features, show text area */}
                                    {isTextReasonRequired && (
                                        <div className="mt-4">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Please specify
                                            </label>
                                            {/* max 3`00 characters */}
                                            <textarea
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                                                rows={3}
                                                maxLength={300}
                                                placeholder="Please specify the reason for cancellation"
                                                value={cancelationText}
                                                onChange={(e) => setCancelationText(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </div>
                                {allowCancelation && (
                                    <div className="mt-5 sm:mt-6 flex flex-row justify-center">
                                        <button
                                            type="button"
                                            className="rounded-full bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={handleCancelSubscription}
                                        >
                                            Confirm cancellation
                                        </button>
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Cancellation;
