import Tooltip from "@mui/material/Tooltip";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteHistoricalEmails, useMailBotMetadata, useMailBotStatistics } from "hooks/api/mailbot";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";
import { usePusher } from "hooks/usePusher";
import { useEffect, useRef, useState } from "react";
import { useMedia } from "react-use";
import { getMailBotSenderProfiles } from "services/mailbot";
import Loader from "toolkit/Loader";
import Toast from "toolkit/Toast";
import { ISenderProfile } from "types/maintypes";
import { getDisplayNumber, truncateText } from "utils/formatter";
import { MAILBOT_FEATURE_FLAGS } from "../../../constants";
import Pagination from "../../../toolkit/Pagination";
import SearchBar from "../../../toolkit/SearchBar";
import ActivateMailBotButton from "../ActivateMailBotButton";
import BlockSender from "./BlockSender";
import { CustomRule } from "./CustomRule";
import EmailDomainImage from "./EmailDomainImage";
import EmailZapInfoCard from "./EmailZapInfoCard";
import InstructionsCard from "./InstructionCard";
import StatisticsCard from "./StatisticsCard";

interface IColumn {
    key: string;
    heading: string;
    width: string;
}

const COLUMNS: IColumn[] = [
    { key: "sender_email", heading: "Sender's email", width: "w-[18.47vw]" },
    { key: "total_count", heading: "Emails received", width: "w-[12.36vw]" },
    { key: "read_fraction", heading: "Read by you (%)", width: "w-[12.36vw]" },
    { key: "custom_rule", heading: "", width: "w-[22.29vw]" },
    { key: "delete_historical", heading: "", width: "w-[12.36vw]" },
];

const SenderProfilesList = ({ senderProfiles, renderItem }) => {
    const isBulkDeleteEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.BULK_DELETE);
    return (
        <table className="table-auto divide-y divide-gray-200 shadow-none font-[Inter] w-full">
            <thead
                style={{
                    boxShadow: "0px 4px 0px 0px #E5E7EB",
                    background: "linear-gradient(180deg, #F5F6FF 0%, rgba(255, 255, 255, 0) 93.23%)",
                }}
                className="h-[4.63vh] md:h-[3.9vh]"
            >
                {/* Desktop header */}
                <tr className="text-xs text-[#6B7280] hidden md:table-row">
                    {COLUMNS.map((column, index) => {
                        if (column.key === "delete_historical" && !isBulkDeleteEnabled) {
                            return null;
                        }
                        return (
                            <th
                                className={`text-left uppercase text-xs leading-4 font-medium tracking-wider uppercase px-[1.11vw] py-[2.34vh] ${column.width}`}
                            >
                                {column.heading}
                            </th>
                        );
                    })}
                </tr>
                {/* Mobile header */}
                <tr className="text-xs text-[#6B7280] table-row md:hidden">
                    <th
                        className={`text-left uppercase text-xs leading-4 font-medium tracking-wider uppercase pr-[6.4vw] pl-[3.2vw] py-[1.15vh]`}
                    >
                        {/* <input
                            type="checkbox"
                            className="mr-2 border-[#626466]"
                            checked={selectedSenders.length === senderProfiles.length}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedSenders(senderProfiles);
                                } else {
                                    setSelectedSenders([]);
                                }
                            }}
                        /> */}
                        Email
                    </th>
                </tr>
            </thead>
            {/* Desktop view */}
            {senderProfiles.length > 0 && (
                <tbody className="bg-white hidden md:table-row-group">
                    {senderProfiles.map((profile, idx) => (
                        <tr
                            key={profile.id}
                            className={`hover:bg-gray-50 ${idx !== senderProfiles.length - 1 && "border-b"}`}
                        >
                            {COLUMNS.map((column) => {
                                if (column.key === "delete_historical" && !isBulkDeleteEnabled) {
                                    return null;
                                }
                                return (
                                    <td
                                        className={`text-sm text-[#111827] h-[8.45vh] md:h-[7.12vh] px-[1.11vw] py-[2.34vh] ${column.width}`}
                                    >
                                        {renderItem(profile, column.key)}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            )}
            {/* Mobile view */}
            {senderProfiles.length > 0 && (
                <tbody className="bg-white md:hidden">
                    {senderProfiles.map((profile, idx) => (
                        <tr
                            key={profile.id}
                            className={`hover:bg-gray-50 ${idx !== senderProfiles.length - 1 && "border-b"}`}
                        >
                            <td className={`text-sm text-[#111827] h-[8.45vh] pr-[3.2vw] pl-[3.2vw] py-[1.85vh]`}>
                                <div className="flex flex-row items-center">
                                    <div className="w-[46.93vw] flex flex-col gap-[14px]">
                                        <div>{renderItem(profile, "sender_email", 18)}</div>
                                        <div className="flex flex-row items-center justify-between">
                                            {/* mail read and total */}
                                            <p className="text-[Inter] text-[#616265]">
                                                Mails: {renderItem(profile, "total_count")}
                                            </p>
                                            <p className="text-[Inter] text-[#616265]">
                                                Read: {renderItem(profile, "read_fraction")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ml-auto">
                                        {/* block and configure icon */}
                                        {renderItem(profile, "custom_rule")}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            )}
        </table>
    );
};

const paginationLimit = 20;
const showOnboardingLoaderTillSeconds = 600;
const showOnboardingLoaderTillMessages = 5000;

function SenderProfiles() {
    const [senderProfiles, setSenderProfiles] = useState<ISenderProfile[]>([]);
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    const { data: statistics, isPending: statisticsPending } = useMailBotStatistics();
    const isZappedLabelEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.ZAPPED_LABEL);
    let blockedLabel = "black_list";
    if (isZappedLabelEnabled) {
        blockedLabel = "zapped";
    }
    const queryClient = useQueryClient();
    const [senderProfilesPending, setSenderProfilesPending] = useState(true);
    // Consider onboarding done otherwise API lag will show user that onboarding is in progress
    const [onboardingComplete, setOnboardingComplete] = useState(true);
    const onboardingLoaderTimeRef = useRef(null) as any;
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [optionClicked, setOptionClicked] = useState();
    const [queryFilteredCount, setQueryFilteredCount] = useState(0);
    const [queryFilteredCurrentPage, setQueryFilteredCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    let totalPages = Math.ceil(count / paginationLimit);
    if (searchQuery !== "") {
        totalPages = Math.ceil(queryFilteredCount / paginationLimit);
    }
    // We need to detect media query changes to update the UI, using tailwind `md:hidden` will not remove it from the DOM
    const isMobile = useMedia("(max-width: 767px)");
    const isDesktop = !isMobile;
    const deleteHistoricalEmailsMutation = useDeleteHistoricalEmails();
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("");
    const [toastHeading, setToastHeading] = useState("");
    const [toastText, setToastText] = useState("");

    if (
        !statisticsPending &&
        statistics.total_processed_count >= showOnboardingLoaderTillMessages &&
        !onboardingComplete
    ) {
        setOnboardingComplete(true);
    }

    usePusher("sender-profiles", "statistics", (data) => {
        queryClient.setQueryData(["mailbot-statistics"], data.statistics);
        if (searchQuery === "" && currentPage === 1 && optionClicked === undefined) {
            // Refresh the sender profiles list only if no search query, no selected senders, no option clicked
            setSenderProfiles(data.sender_profiles.results);
            setCount(data.sender_profiles.count);
        }
    });

    usePusher("sender-profiles", "onboarding-complete", (_: any) => {
        setOnboardingComplete(true);
        queryClient.invalidateQueries({
            queryKey: ["mailbot-metadata"],
        });
        getMailBotSenderProfiles(currentPage, paginationLimit).then((profiles) => {
            setSenderProfiles(profiles.results);
            setCount(profiles.count);
            setSenderProfilesPending(false);
        });
    });

    useEffect(() => {
        if (!statisticsPending) {
            if (mailbotMetadata.onboarding_failed === true) {
                // Currently we are not showing any error message to the user
                setOnboardingComplete(true);
            } else if (
                mailbotMetadata.onboarding_completed === undefined ||
                mailbotMetadata.onboarding_completed === null
            ) {
                const secondsSinceMailBotLastEnabled =
                    (new Date().getTime() - new Date(mailbotMetadata.last_enabled_at).getTime()) / 1000;
                if (
                    secondsSinceMailBotLastEnabled < showOnboardingLoaderTillSeconds &&
                    statistics.total_processed_count < showOnboardingLoaderTillMessages
                ) {
                    setOnboardingComplete(false);
                    // Set onboarding to complete again after 10 minutes since the last time mailbot was enabled
                    onboardingLoaderTimeRef.current = setTimeout(
                        () => {
                            setOnboardingComplete(true);
                        },
                        (showOnboardingLoaderTillSeconds - secondsSinceMailBotLastEnabled) * 1000,
                    );
                }
            } else if (mailbotMetadata?.onboarding_completed === true) {
                setOnboardingComplete(true);
            }
        }
        return () => {
            if (onboardingLoaderTimeRef.current) {
                clearTimeout(onboardingLoaderTimeRef.current);
            }
        };
    }, [mailbotMetadata.onboarding_completed, mailbotMetadata.onboarding_failed, statisticsPending]);

    useEffect(() => {
        getMailBotSenderProfiles(currentPage, paginationLimit).then((profiles) => {
            setSenderProfiles(profiles.results);
            setCount(profiles.count);
            setSenderProfilesPending(false);
        });
    }, [currentPage]);

    useEffect(() => {
        if (searchQuery !== "") {
            getMailBotSenderProfiles(queryFilteredCurrentPage, paginationLimit, searchQuery).then((profiles) => {
                setSenderProfiles(profiles.results);
                setQueryFilteredCount(profiles.count);
            });
        }
    }, [queryFilteredCurrentPage]);
    const postUpdateTraining = ({ trainingStatus, profiles }) => {
        const newProfiles = senderProfiles.map((p) => {
            if (profiles.includes(p)) {
                return {
                    ...p,
                    user_training: trainingStatus,
                    blocked_by_emailzap: false,
                };
            } else {
                return p;
            }
        });
        setSenderProfiles(newProfiles);
    };

    const postSenderWorkflowDeleted = (senderProfileIds) => {
        const newProfiles = senderProfiles.map((p) => {
            if (senderProfileIds.includes(p.id)) {
                return { ...p, workflow_tag: null };
            } else {
                return p;
            }
        });
        setSenderProfiles(newProfiles);
    };

    const postSenderWorkflowConfigured = (profiles, label) => {
        const newProfiles = senderProfiles.map((p) => {
            if (profiles.includes(p)) {
                return { ...p, workflow_tag: label };
            } else {
                return p;
            }
        });
        setSenderProfiles(newProfiles);
    };

    const handleSearch = async (query) => {
        try {
            // Return first page of results
            const profiles = await getMailBotSenderProfiles(1, paginationLimit, query);
            setSenderProfiles(profiles.results);
            setQueryFilteredCount(profiles.count);
            setQueryFilteredCurrentPage(1);
        } catch (error) {
            console.error("Error fetching sender profiles:", error);
        }
    };

    const nextPage = () => {
        if (searchQuery !== "") {
            setQueryFilteredCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        } else {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        }
    };

    const prevPage = () => {
        if (searchQuery !== "") {
            setQueryFilteredCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        } else {
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        }
    };

    const CustomRuleAndBlockSender = (profile) => {
        if (profile.workflow_tag) {
            return (
                <div className="md:w-[17vw]">
                    <CustomRule
                        profiles={[profile]}
                        colorFillHex="#000000"
                        ruleEnabled={true}
                        ruleName={profile.workflow_tag}
                        currentSenderProfileId={profile.id}
                        optionClicked={optionClicked}
                        setOptionClicked={setOptionClicked}
                        postSenderWorkflowConfigured={postSenderWorkflowConfigured}
                        postSenderWorkflowDeleted={postSenderWorkflowDeleted}
                    />
                </div>
            );
        } else if (profile.user_training === blockedLabel || profile.blocked_by_emailzap) {
            return (
                <div className="md:w-[17vw]">
                    <BlockSender
                        profiles={[profile]}
                        colorFillHex="#EE6057"
                        senderBlocked={true}
                        blockedByEmailZap={profile.blocked_by_emailzap}
                        postUpdateTraining={postUpdateTraining}
                    />
                </div>
            );
        } else {
            return (
                <div className="flex flex-col items-start md:flex-row md:w-[17vw]">
                    <BlockSender profiles={[profile]} colorFillHex="#6E57EE" postUpdateTraining={postUpdateTraining} />
                    {/* Align second item to flex end */}
                    <div className="md:ml-auto">
                        <CustomRule
                            profiles={[profile]}
                            currentSenderProfileId={profile.id}
                            optionClicked={optionClicked}
                            setOptionClicked={setOptionClicked}
                            postSenderWorkflowConfigured={postSenderWorkflowConfigured}
                            postSenderWorkflowDeleted={postSenderWorkflowDeleted}
                        />
                    </div>
                </div>
            );
        }
    };

    const renderItem = (profile: ISenderProfile, key: string, maxEmailLength = 50) => {
        const showDeleteSuccessToast = () => {
            setToastHeading("Deletion of Emails started!");
            setToastText("The sender's emails will be deleted soon!");
            setToastVariant("success");
            setShowToast(true);
        };

        const showDeleteErrorToast = () => {
            setToastHeading("Error in deletion of Historical emails!");
            setToastText("Some error occurred in deletion of historical emails");
            setToastVariant("failure");
            setShowToast(true);
        };

        switch (key) {
            case "sender_email":
                return (
                    <div className="flex flex-row items-center gap-[0.83vw]">
                        <EmailDomainImage
                            name={profile.sender_name}
                            email={profile.sender_email}
                            domain={profile.sender_domain}
                        />
                        <Tooltip
                            title={profile.sender_email.length > maxEmailLength ? profile.sender_email : ""}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                        >
                            <p>{truncateText(profile[key], maxEmailLength)}</p>
                        </Tooltip>
                    </div>
                );
            case "read_fraction":
                return (profile[key] * 100).toFixed(1) + "%";
            case "delete_historical":
                return (
                    <button
                        className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 py-1 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                        onClick={() => {
                            deleteHistoricalEmailsMutation.mutate(
                                { profile },
                                {
                                    onSuccess: showDeleteSuccessToast,
                                    onError: showDeleteErrorToast,
                                },
                            );
                        }}
                    >
                        Delete Historical Emails
                    </button>
                );
            case "custom_rule":
                return CustomRuleAndBlockSender(profile);
            case "total_count":
                return getDisplayNumber(profile[key]);
            default:
                return profile[key];
        }
    };

    if (
        senderProfilesPending ||
        statisticsPending ||
        mailbotMetadataPending ||
        deleteHistoricalEmailsMutation.isPending
    ) {
        return <Loader />;
    }

    return (
        <div className="md:w-[77.8vw] w-[89.33vw] mt-4 ml-4">
            <ActivateMailBotButton />
            <InstructionsCard />
            {!onboardingComplete && (
                <div className="mt-[1.17vh] flex flex-row justify-center align-center gap-[2vw] md:gap-[1.17vw]">
                    <Loader fullScreen={false} size={6} />
                    <p className="font-[Inter] md:text-[14px] text-[12px]">
                        Analysing your inbox. Can take upto 10 mins
                    </p>
                </div>
            )}
            <div className="flex flex-row justify-between md:justify-normal md:gap-[1.38vw] mt-[1.17vh]">
                <StatisticsCard
                    metricTitle="Total emails scanned"
                    totalMetric={statistics.total_processed_count}
                    yesterdayMetric={statistics.processed_count_yesterday}
                    yesterdayMetricTitle={`Mail${statistics.processed_count_yesterday > 1 ? "s" : ""} scanned yesterday`}
                />
                <StatisticsCard
                    metricTitle={isDesktop ? "Emails moved out of your inbox" : "Emails moved out"}
                    metricSubtitle={isDesktop ? "" : "of your inbox"}
                    totalMetric={statistics.total_moved_count}
                    yesterdayMetric={statistics.moved_count_yesterday}
                    yesterdayMetricTitle={`Mail${statistics.moved_count_yesterday > 1 ? "s" : ""} moved out yesterday`}
                />

                {isDesktop && <EmailZapInfoCard />}
            </div>
            {isMobile && (
                <div className="mt-[1.17vh]">
                    <EmailZapInfoCard />
                </div>
            )}
            <div className="mt-[1.17vh] flex flex-row items-center">
                <SearchBar
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch("")}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
            </div>
            <div className="shadow w-full mt-[1.17vh]">
                <SenderProfilesList senderProfiles={senderProfiles} renderItem={renderItem} />
                {senderProfiles.length === 0 && (
                    <div className="flex p-2 justify-center items-center">No sender profiles found.</div>
                )}
            </div>
            {searchQuery === "" ? (
                <Pagination
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    count={count}
                    paginationLimit={paginationLimit}
                    nextPage={nextPage}
                    prevPage={prevPage}
                />
            ) : (
                <Pagination
                    totalPages={totalPages}
                    setCurrentPage={setQueryFilteredCurrentPage}
                    currentPage={queryFilteredCurrentPage}
                    count={queryFilteredCount}
                    paginationLimit={paginationLimit}
                    nextPage={nextPage}
                    prevPage={prevPage}
                />
            )}
            {showToast && (
                <Toast
                    heading={toastHeading}
                    text={toastText}
                    show={showToast}
                    setShow={setShowToast}
                    notificationType={toastVariant}
                    centralize={true}
                />
            )}
        </div>
    );
}

export default SenderProfiles;
