import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Workflow from "./Workflow";
import { Link } from "react-router-dom";
import WorkflowList from "./WorkflowList";
import EditWorkflow from "./EditWorkflow";

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
};

function Jarvis() {
    const navigate = useNavigate();
    const location = useLocation();

    const tabs = [
        {
            name: "Create workflow",
            path: "/create",
            component: <Workflow />,
            href: "/workflows/create",
            current: ["/workflows", "/workflows/create"].includes(location.pathname),
        },
        {
            name: "All Workflows",
            path: "/list",
            component: <WorkflowList />,
            href: "/workflows/list",
            current: "/workflows/list" === location.pathname,
        },
    ];

    return (
        <div>
            <div className={`mx-auto sm:hidden px-4 sm:px-10}`}>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    onChange={(e) => {
                        const nextHref = tabs.find((x) => x.name === e.target.value)?.href;
                        if (nextHref) navigate(nextHref);
                    }}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name} selected={tab.current}>
                            {tab.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block border-b border-gray-200 pl-1">
                <nav className="flex pl-5" aria-label="Tabs">
                    {tabs.map((tab) => (
                        <Link
                            key={tab.name}
                            to={tab.href}
                            className={classNames(
                                tab.current
                                    ? "text-gray-900 border-b border-gray-900"
                                    : "text-gray-500 hover:text-gray-700",
                                "text-sm font-medium pb-3 px-4 font-[Cambay]",
                            )}
                        >
                            {tab.name}
                        </Link>
                    ))}
                </nav>
            </div>
            <div className={"mx-auto px-4 sm:px-10 pt-10"}>
                <Routes>
                    <Route path="/" element={<Workflow />} />
                    <Route path="/edit" element={<EditWorkflow />} />
                    {tabs.map((tab, idx) => (
                        <Route path={tab["path"]} element={tab["component"]} key={idx} />
                    ))}
                </Routes>
            </div>
        </div>
    );
}

export default Jarvis;
