import { getDisplayNumber } from "utils/formatter";

export default function StatisticsCard({
    metricTitle,
    totalMetric,
    yesterdayMetric,
    yesterdayMetricTitle,
    metricSubtitle = "",
}) {
    return (
        <div
            className="w-[41.86vw] md:w-[18.75vw] p-2 rounded-[12px] flex flex-col justify-around items-center md:items-start"
            style={{
                background: "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)",
                border: "1px solid rgba(235, 235, 243, 1)",
            }}
        >
            <p
                className={`font-[Inter] md:ml-[1.11vw] text-xs md:text-[16px] text-[#202224] font-[500] opacity-70 leading-5`}
            >
                {metricTitle}
            </p>
            {metricSubtitle !== "" && (
                <p
                    className={`font-[Inter] md:ml-[1.11vw] text-xs md:text-[16px] text-[#202224] font-[500] opacity-70 leading-5`}
                >
                    {metricSubtitle}
                </p>
            )}
            <p className="font-[700] md:ml-[1.11vw] text-[20px] md:text-[28px] font-[Inter] leading-8">
                {getDisplayNumber(totalMetric)}
            </p>
            <div className="md:ml-[1.11vw] flex flex-row gap-[3vw] md:gap-[0.6vw] items-center">
                <svg viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[10px] w-[20px]">
                    <path
                        d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z"
                        fill="#00B69B"
                    />
                </svg>
                <div className="flex flex-row gap-[1vw] md:gap-[0.2vw]">
                    <p className="text-xs md:text-[12px] text-[#00B69B] font-medium font-[Inter] leading-5">
                        {getDisplayNumber(yesterdayMetric)}
                    </p>
                    <p className="text-xs md:text-[12px] text-[#81868F] font-[Inter] leading-5">
                        {yesterdayMetricTitle}
                    </p>
                </div>
            </div>
        </div>
    );
}
