export const MAIL_AUTH_TOKEN_FAILURE_HTTP_CODES = [400, 403];
export const EMAILZAP_BASE_URL = process.env.REACT_APP_EMAILZAP_BASE_URL;
export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const EMAIL_LIST_OF_CAL_BOT_TESTERS = [
    "executivefunction2@gmail.com",
    "janki@infocusp.com",
    "jainam@infocusp.com",
    "luke@alpha-sense.com",
    "nicole0831@gmail.com",
    "ankitmail123@gmail.com",
    "russelleric@gmail.com",
    "james@fajita.vc",
    "nandan.luthra9@gmail.com",
    "sanjana.pachory@gmail.com",
    "mohini.verma88@gmail.com",
    "chandan1312@gmail.com",
];

export const EMAIL_LIST_OF_CLUB_EMAILS_TESTERS = [
    "executivefunction2@gmail.com",
    "janki@infocusp.com",
    "jainam@infocusp.com",
    "luke@alpha-sense.com",
    "nicole0831@gmail.com",
    "ankitmail123@gmail.com",
    "russelleric@gmail.com",
    "james@fajita.vc",
    "nandan.luthra9@gmail.com",
    "sanjana.pachory@gmail.com",
    "mohini.verma88@gmail.com",
    "chandan1312@gmail.com",
    "manishproplayer1@gmail.com",
    "ollehuduganithin@gmail.com",
    "ad7364232@gmail.com",
    "akhilhanda14@gmail.com",
    "gwen92570@gmail.com",
    "rajkumarsingh011223@gmail.com",
    "lucia.steiner2@gmail.com",
    "muhammedhamzanatty@gmail.com",
    "ankushmanavat@gmail.com",
    "bamilmukesh@gmail.com",
    "brajkumar986@gmail.com",
    "kranjeet68997@gmail.com",
    "aditya.parihar@emeritus.org",
    "rs9749213@gmail.com",
    "boehawks310@gmail.com",
    "maysvictor042@gmail.com",
    "ytsraj9@gmail.com",
    "afjalalam044@gmail.com",
    "archithaa653@gmail.com",
    "seigofuta@gmail.com",
    "soudabeevi57@gmail.com",
    "gurungmythtitans@gmail.com",
    "avneetparashar06@gmail.com",
    "raylynntricycle@gmail.com",
    "shakib224321@gmail.com",
    "pythondeveloper1914@gmail.com",
    "robenabee@gmail.com",
    "msudha1958@gmail.com",
    "jusufmehmedovic07@gmail.com",
    "kiroden510@gmail.com",
    "fuzailbarkati123@gmail.com",
    "tirupatirao2481960@gmail.com",
    "anmol@investmsme.com",
    "ernestbranham59@gmail.com",
    "gopalakrishnanc0123@gmail.com",
    "renjianwu@hireez.com",
    "manohar200029@gmail.com",
    "omm641173@gmail.com",
    "mikehenders098@gmail.com",
    "cbwreddy@gmail.com",
    "mandalgulab472@gmail.com",
    "vikramganaur@gmail.com",
    "glennthompson308@gmail.com",
    "cherylrosas984@gmail.com",
    "ethanwmartin12@gmail.com",
    "ramdhani7863@gmail.com",
    "manasdip497@gmail.com",
    "mylife1234oooq@gmail.com",
    "paulinogaitan13@gmail.com",
    "rettigstephen65@gmail.com",
    "malotra0000@gmail.com",
    "rajeeshavanoor@gmail.com",
    "ravivanu123@gmail.com",
    "rohansharma08072002@gmail.com",
    "kentaviousbassett21@gmail.com",
    "junyrobinson7808@gmail.com",
    "indradharmakari03@gmail.com",
    "lifesab1982@gmail.com",
    "shwethatilak12@gmail.com",
    "surabiddinsekh@gmail.com",
    "limbuanugrah57@gmail.com",
    "shirafvb1952@gmail.com",
    "isabelasuarezcruz@gmail.com",
    "morgorm@gmail.com",
    "afsanahussain17139@gmail.com",
    "mh48syed@gmail.com",
    "emily22548@gmail.com",
    "mcqueentangie@gmail.com",
    "mfaid1492@gmail.com",
    "missrumabagum@gmail.com",
    "maevapineaultcolangelo@gmail.com",
    "tanusreesree812@gmail.com",
    "shiburajannp@gmail.com",
    "dharan3060@gmail.com",
    "marykairu67@gmail.com",
    "murugansivan8170@gmail.com",
    "liseouellet2@gmail.com",
    "mohinbanva74@gmail.com",
    "dasarikarthik78@gmail.com",
    "benjermin4pp5@gmail.com",
    "unclebojones03@gmail.com",
    "joseomarferreira8@gmail.com",
    "dorisdurham55@gmail.com",
    "jaydensummerzap26@gmail.com",
    "mustakeemahmad009@gmail.com",
    "arakhileshyadav@gmail.com",
    "sk9959495@gmail.com",
    "alexandanimations@gmail.com",
    "akashverma283122@gmail.com",
    "bigmark5485@gmail.com",
    "golucom.yadav245@gmail.com",
    "kokatesakshi2006@gmail.com",
    "harrissnthony@gmail.com",
    "sukhpasukha@gmail.com",
    "40338@childrensguild.org",
    "teweldeleake1102@gmail.com",
    "mjjfarley@gmail.com",
    "shubhamkumar843498@gmail.com",
    "jaswanthkothamasu7@gmail.com",
    "beautifulblu54@gmail.com",
    "executivefunction3@gmail.com",
];

export const DOMAIN_LIST_OF_CAL_BOT_TESTERS = ["execfn.com", "studiomgmt.co"];

export const DOMAIN_LIST_OF_CLUB_EMAIL_TESTERS = ["execfn.com", "studiomgmt.co"];

// TODO: Update APPLICATION_TAGS key from zap to bot
export const APPLICATION_TAGS = {
    EMAILZAP: "mailbot",
    CALENDARZAP: "calendarbot",
    REMINDERZAP: "reminderbot",
    STORAGEZAP: "storagebot",
};

export enum MAILBOT_FEATURE_FLAGS {
    EMAILZAP = "email_zap",
    DIGEST_EMAIL = "digest_email",
    AUTO_ARCHIVAL = "auto-archival",
    FIRST_TIME_SENDER_OVERLAY = "first-time-sender-overlay",
    AI_AUTO_RESPONDER = "ai-auto-responder",
    JARVIS = "jarvis",
    SECONDARY_PROFILES = "secondary-profiles",
    ZAPPED_LABEL = "zapped-label",
    BULK_DELETE = "bulk-delete",
    UNSUBSCRIBE = "unsubscribe",
}

export enum SubscriptionStatus {
    STATUS_INCOMPLETE = "incomplete",
    STATUS_INCOMPLETE_EXPIRED = "incomplete_expired",
    STATUS_TRIALING = "trialing",
    STATUS_ACTIVE = "active",
    STATUS_PAST_DUE = "past_due",
    STATUS_CANCELED = "canceled",
    STATUS_UNPAID = "unpaid",
}

export const CURRENCY_SYMBOLS = {
    usd: "$",
    inr: "₹",
    eur: "€",
};

export const INTERVAL_SYMBOLS = {
    month: "mo",
    year: "year",
};
