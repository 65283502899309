import { backendServerAxiosInstance } from "services";
import { deleteAllCookies, getCookie } from "utils/cookies";

export const getUser = async () => {
    try {
        const response = await backendServerAxiosInstance.get("/mailbot/users/me/");
        return response.data;
    } catch (error: any) {
        const serviceProvider = getCookie("service_provider");
        if (serviceProvider === "google" || serviceProvider === "microsoft") {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                deleteAllCookies();
                window.location.replace("/");
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser
                console.log(error.request);
            }
        }
    }
};

export const logoutUser = async () => {
    const response = await backendServerAxiosInstance.post("/accounts/user/me/logout/");
    return response.data;
};
