import { useContext, useEffect, useState } from "react";
import { Link, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import {
    useIsMailBotActive,
    useMailBotMetadata,
    useMailBotPreferences,
    useUpdateMailBotPreferences,
} from "hooks/api/mailbot";
import { useLatestSubscription } from "hooks/api/payments";
import googleAuthorize from "images/btn_google_signin_light_normal_web_short.png";
import microsoftLoginButton from "images/ms-symbollockup_signin_dark.png";
import { loginMailBotProfile } from "services/mailbot";
import Loader from "toolkit/Loader";
import Notification from "toolkit/Notification";
import { getCookie } from "utils/cookies";
import { myContext } from "../../Context";
import { SubscriptionStatus } from "../../constants";
import Subscription from "../Subscription/Subscription";
import Preferences from "./Preferences";
import SenderProfiles from "./SenderProfile/SenderProfiles";

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
};

const NavbarDesktop = ({ tabs }) => {
    return (
        <div className="hidden sm:flex justify-between border-b border-gray-200 pl-1 w-11/12">
            <nav className="flex pl-5" aria-label="Tabs">
                {tabs.map((tab) => (
                    <Link
                        key={tab.name}
                        to={tab.href}
                        className={classNames(
                            tab.current
                                ? "text-gray-900 border-b border-gray-900"
                                : "text-gray-500 hover:text-gray-700",
                            "text-sm font-medium pb-3 px-4 font-[Cambay]",
                        )}
                    >
                        {tab.name}
                    </Link>
                ))}
            </nav>
        </div>
    );
};

const NavbarMobile = ({ tabs }) => {
    const navigate = useNavigate();
    return (
        <div className={`mx-auto sm:hidden px-4 sm:px-10`}>
            <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                onChange={(e) => {
                    const nextHref = tabs.find((x) => x.name === e.target.value)?.href;
                    if (nextHref) navigate(nextHref);
                }}
            >
                {tabs.map((tab) => (
                    <option key={tab.name} selected={tab.current}>
                        {tab.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

const ActivationStatusNotification = () => {
    const [open, setOpen] = useState(true);
    const { setShowDeactivationMessage, showDeactivationMessage, showActivationMessage, setShowActivationMessage } =
        useContext(myContext) as any;

    const onCloseHandler = () => {
        if (showActivationMessage) {
            setShowActivationMessage(false);
        } else if (showDeactivationMessage) {
            setShowDeactivationMessage(false);
        }
        setOpen(false);
    };

    if (showActivationMessage) {
        return (
            <Notification
                title={"Account activation successful"}
                text={"Emailzap has been successfully activated for your account."}
                isSuccessNotification={true}
                open={open}
                onCloseHandler={onCloseHandler}
                initialFocusRef={null}
            >
                <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                            setOpen(false);
                            setShowActivationMessage(false);
                        }}
                    >
                        Okay
                    </button>
                </div>
            </Notification>
        );
    } else {
        return (
            <Notification
                title={"Account deactivation successful"}
                text={
                    "Emailzap has been deactivated. All new Emails post deactivation will go to your inbox. All the emails that were categorised as Low priority/Zero priority when Emailzap was active will stay in respective folders."
                }
                isSuccessNotification={true}
                open={true}
                onCloseHandler={onCloseHandler}
                initialFocusRef={null}
            >
                <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                            setOpen(false);
                            setShowDeactivationMessage(false);
                        }}
                    >
                        Okay
                    </button>
                </div>
            </Notification>
        );
    }
};

function Mailbot() {
    const { showActivationMessage, showDeactivationMessage } = useContext(myContext) as any;
    const location = useLocation();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const { data: mailbotPreferences, isPending: mailbotPreferencesPending } = useMailBotPreferences();
    const updateMailBotPreferencesMutation = useUpdateMailBotPreferences();

    useEffect(() => {
        if (!mailbotPreferencesPending && mailbotPreferences?.digest_timezone === undefined) {
            const timeZoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
            updateMailBotPreferencesMutation.mutate({ ...mailbotPreferences, digest_timezone: timeZoneValue });
        }
    }, [mailbotPreferencesPending]);

    if (mailbotMetadataPending || mailBotEnabledPending || subscriptionPending) {
        return <Loader />;
    }

    let tabs = [
        {
            name: "Home",
            path: "/home",
            component: <SenderProfiles />,
            visible:
                !!subscription &&
                [SubscriptionStatus.STATUS_ACTIVE, SubscriptionStatus.STATUS_PAST_DUE].includes(
                    subscription.status as SubscriptionStatus,
                ),
        },
        {
            name: "Preferences",
            path: "/preferences",
            component: <Preferences />,
            visible: mailBotEnabled,
        },
        {
            name: "Subscription",
            path: "/subscription",
            component: <Subscription />,
            visible: true,
        },
    ];
    tabs = tabs.filter((tab) => tab.visible);
    tabs.forEach((tab, index) => {
        tab["href"] = `/mail-bot${tab["path"]}`;
        if (index === 0) {
            // First tab should be render at other paths like "/" and "/mail-bot/"
            tab["current"] = ["/", "/mail-bot", tab["href"]].includes(location.pathname);
        } else {
            tab["current"] = tab["href"] === location.pathname;
        }
    });

    const service_provider = getCookie("service_provider");
    let areAllScopesGranted = true;
    if (mailbotMetadata.all_scopes_granted === false) {
        areAllScopesGranted = false;
    }

    if (showActivationMessage === true || showDeactivationMessage === true) {
        return (
            <div className="m-3 d-flex justify-content-center">
                <div style={{ maxWidth: "500px" }}>
                    <ActivationStatusNotification />
                </div>
            </div>
        );
    } else if (!areAllScopesGranted) {
        return (
            <Notification
                title={"Insufficient Permissions"}
                text={"Please grant all permissions for EmailZap to work. Click on the button below to proceed."}
                isSuccessNotification={false}
                open={true}
                onCloseHandler={() => {}}
                initialFocusRef={null}
            >
                {service_provider === "google" && (
                    <div className="flex justify-center">
                        <img
                            className="object-contain mx-auto justify-center my-2 cursor-pointer"
                            src={googleAuthorize}
                            alt="Authorize in with google"
                            onClick={() => loginMailBotProfile("google")}
                        />
                    </div>
                )}
                {service_provider === "microsoft" && (
                    <div className="flex justify-center">
                        <img
                            className="object-contain mx-auto justify-center my-2 cursor-pointer"
                            src={microsoftLoginButton}
                            alt="Authorize in with Microsoft"
                            onClick={() => {
                                loginMailBotProfile("microsoft");
                            }}
                        />
                    </div>
                )}
            </Notification>
        );
    } else if (!subscription && tabs) {
        return (
            <div>
                <div>
                    <NavbarMobile tabs={tabs} />
                    <NavbarDesktop tabs={tabs} />
                    <Routes>
                        {tabs[0] && <Route index element={tabs[0]["component"]} />}
                        {tabs.map((tab) => (
                            <Route path={tab["path"]} element={tab["component"]} />
                        ))}
                    </Routes>
                    <Outlet />
                </div>
            </div>
        );
    } else if (mailbotMetadata.refresh_token_expired) {
        return (
            <Notification
                title={"Refresh token expired"}
                text={"Please re-connect your account to continue using EmailZap."}
                isSuccessNotification={false}
                open={true}
                onCloseHandler={() => {}}
                initialFocusRef={null}
            >
                {service_provider === "google" && (
                    <div className="flex justify-center">
                        <img
                            className="object-contain mx-auto justify-center my-2 cursor-pointer"
                            src={googleAuthorize}
                            alt="Authorize in with google"
                            onClick={() => loginMailBotProfile("google")}
                        />
                    </div>
                )}
                {service_provider === "microsoft" && (
                    <div className="flex justify-center">
                        <img
                            className="object-contain mx-auto justify-center my-2 cursor-pointer"
                            src={microsoftLoginButton}
                            alt="sign in with microsoft"
                            onClick={() => {
                                loginMailBotProfile("microsoft");
                            }}
                        />
                    </div>
                )}
            </Notification>
        );
    } else {
        return (
            <div>
                <div>
                    <NavbarMobile tabs={tabs} />
                    <NavbarDesktop tabs={tabs} />
                    <div>
                        <Routes>
                            {tabs[0] && <Route index element={tabs[0]["component"]} />}
                            {tabs.map((tab) => (
                                <Route path={tab["path"]} element={tab["component"]} />
                            ))}
                        </Routes>
                    </div>
                    <Outlet />
                </div>
            </div>
        );
    }
}

export default Mailbot;
