import Pusher from "pusher-js";
import { useEffect } from "react";
import { backendServerAxiosInstance } from "services";
import { getCookie } from "utils/cookies";
import { useUser } from "./api/accounts";

const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY || "";
const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER || "";

export const usePusher = (componentName, eventName, callback, isPrivateChannel = true) => {
    const { data: user, isPending } = useUser();
    useEffect(() => {
        let pusher;
        if (!isPending) {
            const csrf_token = getCookie("csrftoken");
            pusher = new Pusher(PUSHER_APP_KEY, {
                cluster: PUSHER_APP_CLUSTER,
                channelAuthorization: {
                    transport: "ajax",
                    headers: {
                        "X-CSRFToken": csrf_token,
                    },
                    customHandler: async (params, callback) => {
                        if (isPrivateChannel) {
                            const { socketId, channelName } = params;
                            try {
                                const response = await backendServerAxiosInstance.post("/mailbot/pusher-auth/", {
                                    channel: channelName,
                                    socket_id: socketId,
                                });
                                callback(null, response.data);
                            } catch (error) {
                                callback(new Error(`Error authenticating with server: ${error}`), null);
                            }
                        }
                    },
                },
            });
            let channelName;
            if (isPrivateChannel) {
                channelName = `private-${componentName}-${user.id}`;
            } else {
                channelName = componentName;
            }
            const channel = pusher.subscribe(channelName);
            channel.bind(eventName, (data) => {
                callback(data);
            });
        }
        return () => {
            if (pusher) {
                pusher.disconnect();
            }
        };
    }, [componentName, eventName, isPrivateChannel, user, isPending]);
};
