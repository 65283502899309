import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    getMailBotPreferences,
    updateMailBotPreferences,
    getStatistics,
    activateMailBotProfile,
    deactivateMailBotProfile,
    deleteUserMailBotProfile,
    sendSupportEmail,
    deleteHistoricalEmails,
    unsubscribeSender,
    getCanMailBotDisabled,
    getCanMailBotEnabled,
    getIsMailBotActive,
} from "services/mailbot";

export function useMailBot(select) {
    return useQuery({
        queryKey: ["mailbot"],
        queryFn: getMailBotPreferences,
        select: select,
    });
}

export function useMailBotPreferences() {
    return useMailBot((data) => data.preferences);
}

export function useMailBotMetadata() {
    return useMailBot((data) => data.metadata);
}

export function useMailBotProfileId() {
    return useMailBot((data) => data.id);
}

export function useIsPrimaryProfile() {
    return useMailBot((data) => data.primary);
}

export function useMailBotStatistics() {
    return useQuery({
        queryKey: ["mailbot-statistics"],
        queryFn: getStatistics,
    });
}

export function useUpdateMailBotPreferences() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateMailBotPreferences,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["mailbot"] });
        },
    });
}

export function useActivateMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: activateMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["mailbot"] });
        },
    });
}

export function useDeactivateMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deactivateMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["mailbot"] });
        },
    });
}

export function useDeleteUserMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteUserMailBotProfile,
        onSuccess: () => {
            queryClient.clear();
        },
    });
}

export function useSendSupportEmail() {
    return useMutation({
        mutationFn: sendSupportEmail,
    });
}

export function useDeleteHistoricalEmails() {
    return useMutation({
        mutationFn: deleteHistoricalEmails,
    });
}

export function useUnsubscribeSender() {
    return useMutation({
        mutationFn: unsubscribeSender,
    });
}

export function useCanMailBotDisabled() {
    return useQuery({
        queryKey: ["mailbot", "can-disabled"],
        queryFn: getCanMailBotDisabled,
    });
}

export function useCanMailBotEnabled() {
    return useQuery({
        queryKey: ["mailbot", "can-enabled"],
        queryFn: getCanMailBotEnabled,
    });
}

export function useIsMailBotActive() {
    return useQuery({
        queryKey: ["mailbot", "is-active"],
        queryFn: getIsMailBotActive,
    });
}
