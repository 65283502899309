import { myContext } from "Context";
import { useActivateMailBotProfile, useCanMailBotEnabled, useMailBotPreferences } from "hooks/api/mailbot";
import { useContext } from "react";
import Loader from "toolkit/Loader";

export default function ActivateMailBotButton() {
    const { data: mailbotPreferences, isPending: mailbotPreferencesPending } = useMailBotPreferences();
    const { data: canEnableMailBot, isPending: canEnableMailBotPending } = useCanMailBotEnabled();
    const { setShowActivationMessage } = useContext(myContext) as any;
    const activateMailBotProfileMutation = useActivateMailBotProfile();

    const activateMailBotHandler = () => {
        activateMailBotProfileMutation.mutate(mailbotPreferences, {
            onSuccess: () => {
                setShowActivationMessage(true);
            },
        });
    };
    if (activateMailBotProfileMutation.isPending) {
        return <Loader />;
    } else if (canEnableMailBotPending || mailbotPreferencesPending) {
        return null;
    } else if (canEnableMailBot) {
        return (
            <div className="mb-8 mt-6 mx-auto" style={{ minHeight: "40px", maxHeight: "40px" }}>
                <span className="mr-3">EmailZap is currently de-activated</span>
                <button
                    className="bg-green-600 hover:bg-green-700 text-white font-medium py-1 md:py-2 px-4 md:px-8 rounded-lg"
                    onClick={() => {
                        activateMailBotHandler();
                    }}
                >
                    Activate Now
                </button>
            </div>
        );
    } else {
        return null;
    }
}
