import { useEffect, useState } from "react";

import Loader from "toolkit/Loader";
import { IWorkflow, OrderDirection, WorkflowState } from "types/maintypes";
import { listWorkflows, updateWorkflow } from "services/workflow";
import Toggle from "toolkit/Toggle";
import { deepCopy } from "utils";
import { useNavigate } from "react-router-dom";

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
interface IColumn {
    key: string;
    heading: string;
}

const columns: IColumn[] = [
    { key: "name", heading: "Name" },
    { key: "state", heading: "State" },
    { key: "is_active", heading: "Active" },
    { key: "created", heading: "Created on" },
];

const formatDateTime = (timestamp: string) => {
    const dateObj = new Date(timestamp);
    const formattedDate = dateObj.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: USER_TIMEZONE,
    });

    return formattedDate;
};

const formatState = (state: string) => {
    return <div className="capitalize">{state}</div>;
};

function ListWorkflow() {
    const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
    const [showLoader, setShowLoader] = useState(false);

    const sortField: keyof IWorkflow = "created";
    const sortOrder: OrderDirection = "desc";

    const navigate = useNavigate();

    const fetchWorkflows = async () => {
        try {
            const workflows = await listWorkflows(sortField, sortOrder);
            setWorkflows(workflows);
        } catch (error) {
            console.error(error);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        setShowLoader(true);
        fetchWorkflows();
    }, []);

    const formatActivationStatus = (workflow: IWorkflow) => {
        return (
            <Toggle
                checked={workflow.is_active || false}
                onChangeHandler={async (event) => {
                    try {
                        await updateWorkflow(workflow.id, { is_active: event });
                        const updatedWorkflows = deepCopy(workflows);
                        const index = updatedWorkflows.findIndex((x) => x.id === workflow.id);
                        if (index !== -1) {
                            updatedWorkflows[index]["is_active"] = event;
                            setWorkflows(updatedWorkflows);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }}
                {...(workflow.state !== WorkflowState.Configured
                    ? { tooltip: "cannot activate workflow that hasn't been successfully configured", disabled: true }
                    : {})}
            />
        );
    };

    const formatData = (workflow: IWorkflow, key: string) => {
        switch (key) {
            case "is_active":
                return formatActivationStatus(workflow);

            case "created":
                return formatDateTime(workflow[key] || "");

            case "state":
                return formatState(workflow[key]);

            default:
                return workflow[key];
        }
    };

    const navigateToEdit = (workflowId: number) => {
        navigate(`/workflows/edit?id=${workflowId}`);
    };

    return (
        <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full table-auto divide-y divide-gray-200 shadow-none font-[Inter]">
                            <thead className="bg-gradient-to-b from-[#F5F6FF] via-white to-white pa-2">
                                <tr className="text-xs text-[#6B7280]">
                                    {columns.map((column, idx) => (
                                        <th
                                            className={`${
                                                column.key === "name" ? "w-1/2" : ""
                                            } px-4 py-5 text-left uppercase text-xs leading-4 font-medium tracking-wider`}
                                            key={`heading-${idx}`}
                                        >
                                            {column.heading}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {!showLoader && (
                                <tbody className="bg-white">
                                    {workflows.map((workflow, idx) => (
                                        <tr
                                            key={`tr-${idx}`}
                                            className={`hover:bg-gray-50 cursor-pointer ${
                                                idx !== workflows.length - 1 && "border-b"
                                            }`}
                                            onClick={() => {
                                                navigateToEdit(workflow.id);
                                            }}
                                        >
                                            {columns.map((column, columnIdx) => (
                                                <td
                                                    className="px-4 py-3 text-sm text-[#111827]"
                                                    key={`tr-${idx}-${columnIdx}`}
                                                >
                                                    {formatData(workflow, column.key)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                        {!showLoader && workflows.length === 0 && (
                            <div className="flex justify-center items-center">No workflows found.</div>
                        )}
                        {showLoader && <Loader fullScreen={false} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListWorkflow;
