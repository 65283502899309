import { useMedia } from "react-use";
import { nameInitials } from "utils/formatter";

export default function EmailDomainImage({ name, email, domain }) {
    const src = `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=256&url=https://${domain}`;
    const isMobile = useMedia("(max-width: 767px)");
    return (
        <object
            type="image/png"
            data={src}
            width={isMobile ? 24 : 32}
            height={isMobile ? 24 : 32}
            aria-label={`Domain: ${domain}`}
        >
            <div
                style={{
                    height: isMobile ? 24 : 32,
                    width: isMobile ? 24 : 32,
                    backgroundColor: "#6E57EE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "50%",
                }}
            >
                {nameInitials(name || email)}
            </div>
        </object>
    );
}
