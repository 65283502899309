import { Listbox } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import {
    ArchiveBoxArrowDownIcon,
    EnvelopeOpenIcon,
    ExclamationCircleIcon,
    StarIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import Backdrop from "@mui/material/Backdrop";
import { useIsMailBotActive } from "hooks/api/mailbot";
import { useMedia } from "react-use";
import { configureSenderWorkflows, deleteConfiguredSenderWorkflows } from "services/mailbot";
import { ISenderProfile } from "types/maintypes";
const CustomRuleMapping = {
    star_email_action: "Mark as starred",
    archive_email_action: "Archive",
    trash_email_action: "Move to trash",
    mark_email_as_read_action: "Mark as read",
    spam_email_action: "Mark as spam",
};

interface ICustomRuleOption {
    label: string;
    value: string;
    icon: any;
}

const CUSTOM_RULE_OPTIONS: ICustomRuleOption[] = [
    { label: "trash_email_action", value: "Deliver to trash", icon: TrashIcon },
    {
        label: "spam_email_action",
        value: "Move to spam",
        icon: ExclamationCircleIcon,
    },
    {
        label: "mark_email_as_read_action",
        value: "Mark read",
        icon: EnvelopeOpenIcon,
    },
    { label: "star_email_action", value: "Mark as starred", icon: StarIcon },
    {
        label: "archive_email_action",
        value: "Archive",
        icon: ArchiveBoxArrowDownIcon,
    },
];

const DropDownForCustomRule = ({ onOptionClick, onClose }) => {
    return (
        <div
            className={`rounded-[12px] bg-white w-full h-full`}
            style={{
                border: "1px solid #DFEAF2",
                boxShadow: "4px 4px 20px -10px #9A999B66",
            }}
        >
            <div
                className="rounded-[12px] flex flex-row items-center justify-around"
                style={{
                    background: "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)",
                    border: "1px solid #EBEBF3",
                    height: "4.9vh",
                }}
            >
                <p className="text-[10px] text-[#202224] font-[Inter] opacity-70 ml-[3.2vw] md:ml-[0.55vw]">
                    Changes will be applied to future emails from this sender.
                </p>
            </div>
            <Listbox>
                <Listbox.Options static>
                    {CUSTOM_RULE_OPTIONS.map((rule, idx) => (
                        <div>
                            <Listbox.Option
                                key={rule.label}
                                value={rule.value}
                                className="ml-[0.83w] mt-[1.56vh] h-[1.95vh]"
                            >
                                <button
                                    className="text-[14px] text-[#111827] font-[Inter] flex flex-row gap-[8px] items-center"
                                    onClick={() => onOptionClick(rule.label)}
                                >
                                    <rule.icon className="cursor-pointer ml-4 w-[3.7vw] h-[1.62vh] md:w-[0.97vw] md:h-[1.36vh]" />
                                    {rule.value}
                                </button>
                            </Listbox.Option>
                            {idx === 1 && (
                                <hr
                                    style={{
                                        border: "1px dashed #EBEBF3",
                                        marginTop: "1.56vh",
                                    }}
                                />
                            )}
                            {idx === CUSTOM_RULE_OPTIONS.length - 1 && (
                                <XCircleIcon
                                    className="cursor-pointer w-[7vw] h-[3vh] md:w-[2vw] md:h-[3vh] mx-auto mt-[1.56vh]"
                                    onClick={onClose}
                                    fill="#6E57EE"
                                />
                            )}
                        </div>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
};

const CustomRuleIcon = ({ mailBotEnabled, colorFillHex }) => {
    return (
        <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="md:w-[16px] md:h-[16px] w-[12px] h-[12px]"
        >
            <path
                d="M11.5102 4.12224L11.9032 2.10921C11.9711 1.75937 11.9007 1.40368 11.7034 1.10729C11.5061 0.81089 11.2051 0.609672 10.8577 0.540376L8.25454 0.0252129H8.25371C7.54406 -0.116725 6.83856 0.355018 6.69599 1.07807L4.46333 12.5142C4.43266 12.6711 4.45919 12.8323 4.53629 12.9709L6.03847 15.6593C6.13463 15.8322 6.3021 15.9524 6.49525 15.9891C6.53504 15.9967 6.57649 16 6.61629 16C6.76883 16 6.91888 15.9466 7.03826 15.8472L9.45896 13.835C9.57668 13.7365 9.65792 13.6004 9.68776 13.4493L11.5083 4.12644C11.5083 4.1256 11.5083 4.1256 11.5091 4.12477V4.12393L11.5102 4.12224ZM7.99757 1.33695L10.6016 1.85212L10.3363 3.21056L7.73314 2.6954L7.99842 1.33695H7.99757ZM8.73208 11.4246L7.3725 11.1565L6.13891 10.861L7.47695 4.00624L10.0802 4.5214L8.73208 11.4246ZM6.80709 14.3075L5.81392 12.53L5.88356 12.1718L7.09227 12.4607L8.47673 12.7345L8.43362 12.9549L6.80709 14.3075ZM4.12515 5.70351L1.65967 7.92366L4.12345 10.1271C4.39703 10.3726 4.42189 10.7943 4.17899 11.0706C4.04718 11.2192 3.86562 11.2952 3.68325 11.2952C3.52656 11.2952 3.36904 11.2401 3.24304 11.1266L0.223011 8.42464C0.0820776 8.29773 0 8.11655 0 7.92619C0 7.73583 0.0804159 7.55381 0.220528 7.42691L3.24056 4.70745C3.51414 4.46116 3.93279 4.48535 4.17736 4.75922C4.42191 5.03476 4.39789 5.45639 4.12596 5.7027L4.12515 5.70351ZM15.9992 7.92601C15.9992 8.11637 15.9179 8.29756 15.7762 8.42446L12.7561 11.1264C12.6301 11.2391 12.4726 11.295 12.3159 11.295C12.1336 11.295 11.9512 11.2191 11.8202 11.0704C11.5773 10.7949 11.6022 10.3724 11.8757 10.127L14.3395 7.92348L11.874 5.70333C11.6013 5.45703 11.5781 5.03538 11.8226 4.75985C12.0664 4.48515 12.4859 4.46178 12.7594 4.70808L15.7795 7.42753C15.9204 7.55444 16 7.73646 16 7.92682L15.9992 7.92601Z"
                fill={mailBotEnabled ? colorFillHex : "#AAAAAA"}
            />
        </svg>
    );
};

export const CustomRule = ({
    profiles,
    currentSenderProfileId,
    optionClicked,
    setOptionClicked,
    postSenderWorkflowConfigured,
    postSenderWorkflowDeleted,
    colorFillHex = "#6E57EE",
    ruleEnabled = false,
    ruleName = "",
}) => {
    const isMobile = useMedia("(max-width: 767px)");
    const isDesktop = !isMobile;
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();

    // Event handlers
    const onRuleConfigure = async (profiles: ISenderProfile[], label: string) => {
        if (!mailBotEnabled) return;
        try {
            await configureSenderWorkflows(profiles, label);
            postSenderWorkflowConfigured(profiles, label);
        } catch (error) {
            console.error("Error configuring sender workflow:", error);
        }
    };

    const onRuleDelete = async (profiles: ISenderProfile[]) => {
        if (!mailBotEnabled) return;
        try {
            // comma separated string of profile ids
            const senderProfileIds = profiles.map((p) => p.id);
            await deleteConfiguredSenderWorkflows(senderProfileIds.join(","));
            postSenderWorkflowDeleted(senderProfileIds);
        } catch (error) {
            console.error("Error deleting configured sender workflow:", error);
        }
    };

    const onRuleToggle = async (profiles?: ISenderProfile[], label: string = "") => {
        if (!mailBotEnabled) {
            return;
        }
        if (profiles === undefined) {
            console.error("No profiles selected");
            return;
        }
        if (profiles.every((p) => p.workflow_tag !== null)) {
            await onRuleDelete(profiles);
        } else if (profiles.every((p) => p.workflow_tag === null)) {
            await onRuleConfigure(profiles, label);
        } else {
            console.error("Cannot configure and delete rules at the same time");
        }
    };

    const toggleClick = (option) => {
        if (!mailBotEnabled) return;
        if (optionClicked === option) {
            setOptionClicked(undefined);
        } else {
            setOptionClicked(option);
        }
    };

    if (mailBotEnabledPending) {
        return null;
    }

    return (
        <div className="flex flex-col md:flex-row items-center">
            <div className="flex flex-row items-center gap-[2vw] md:gap-[0.2vw]">
                <CustomRuleIcon mailBotEnabled={mailBotEnabled} colorFillHex={colorFillHex} />
                <div>
                    {ruleEnabled && (
                        <p
                            className={`text-[12px] md:text-[14px] font-[Cambay] font-medium underline`}
                            style={{
                                color: mailBotEnabled ? colorFillHex : "#AAAAAA",
                            }}
                        >
                            {CustomRuleMapping[ruleName]}
                        </p>
                    )}
                    {!ruleEnabled && (
                        <>
                            <p
                                onClick={() => {
                                    if (profiles.length > 0) {
                                        toggleClick(currentSenderProfileId);
                                    }
                                }}
                                className={`text-[14px] hidden md:block font-[Cambay] font-medium underline`}
                                style={{
                                    color: mailBotEnabled ? colorFillHex : "#AAAAAA",
                                    cursor: profiles.length > 0 && mailBotEnabled ? "pointer" : "not-allowed",
                                }}
                            >
                                Add custom rule
                            </p>
                            <p
                                onClick={() => {
                                    if (profiles.length > 0) {
                                        toggleClick(currentSenderProfileId);
                                    }
                                }}
                                className={`text-[12px] md:hidden font-[Cambay] font-medium underline`}
                                style={{
                                    color: mailBotEnabled ? colorFillHex : "#AAAAAA",
                                    cursor: profiles.length > 0 && mailBotEnabled ? "pointer" : "not-allowed",
                                }}
                            >
                                Custom rule
                            </p>
                        </>
                    )}
                    {isDesktop && currentSenderProfileId === optionClicked && (
                        <div className="w-[11.94vw] h-[27vh] mt-2 absolute hidden md:block">
                            <DropDownForCustomRule
                                onOptionClick={async (label) => {
                                    toggleClick(currentSenderProfileId);
                                    await onRuleToggle(profiles, label);
                                }}
                                onClose={() => toggleClick(undefined)}
                            />
                        </div>
                    )}
                    {isMobile && optionClicked === currentSenderProfileId && (
                        <Backdrop open={true} className="z-10" onClick={() => toggleClick(undefined)}>
                            <div className="w-full h-[30vh] fixed bottom-0 left-0">
                                <DropDownForCustomRule
                                    onOptionClick={async (label) => {
                                        toggleClick(currentSenderProfileId);
                                        await onRuleToggle(profiles, label);
                                    }}
                                    onClose={() => toggleClick(undefined)}
                                />
                            </div>
                        </Backdrop>
                    )}
                </div>
            </div>
            {ruleEnabled && (
                <div className="md:ml-auto">
                    <p
                        className="text-[12px] md:text-[14px] font-[Cambay] mt-[0.7vh] underline cursor-pointer"
                        style={{
                            color: mailBotEnabled ? "#6E57EE" : "#AAAAAA",
                        }}
                        onClick={async () => {
                            await onRuleToggle(profiles);
                        }}
                    >
                        Undo
                    </p>
                </div>
            )}
        </div>
    );
};
