import Tooltip from "@mui/material/Tooltip";
import { useIsMailBotActive } from "hooks/api/mailbot";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";
import { updateUserTrainingStatus } from "services/mailbot";
import { ISenderProfile } from "types/maintypes";
import { MAILBOT_FEATURE_FLAGS } from "../../../constants";

function BlockIcon({ colorFillHex }) {
    const { data: mailBotEnabled } = useIsMailBotActive();
    return (
        <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="md:w-[16px] md:h-[16px] w-[12px] h-[12px]"
        >
            <path
                d="M14.1889 2.90885C13.8616 2.50657 13.4934 2.13838 13.0912 1.81111C11.6593 0.628156 9.85734 -0.0127249 7.99999 0.00019149C5.87821 0.00019149 3.8437 0.842917 2.34295 2.34295C0.842936 3.84365 0.000192603 5.87821 0.000192603 7.99999C-0.012762 9.85734 0.628157 11.6594 1.81111 13.0912C2.13838 13.4934 2.50657 13.8616 2.90885 14.1889C4.34067 15.3718 6.14266 16.0127 8.00001 15.9998C10.1218 15.9998 12.1563 15.1571 13.6571 13.6571C15.1571 12.1564 15.9998 10.1218 15.9998 8.00001C16.0128 6.14266 15.3718 4.34065 14.1889 2.90885ZM1.59975 8.00001C1.59225 6.39228 2.18953 4.83985 3.27361 3.65276C4.3577 2.46503 5.84957 1.72926 7.4519 1.59155C9.0535 1.45382 10.6489 1.92427 11.9199 2.90883L2.90899 11.9198C2.04785 10.7961 1.5868 9.4161 1.59975 8.00001ZM8.00001 14.4003C6.58386 14.4132 5.20378 13.9523 4.08023 13.0912L13.0912 4.08022C14.0757 5.35113 14.5462 6.94662 14.4085 8.54826C14.2707 10.1505 13.535 11.6424 12.3472 12.7265C11.1602 13.8106 9.60775 14.4078 8.00001 14.4003Z"
                fill={mailBotEnabled ? colorFillHex : "#AAAAAA"}
            />
        </svg>
    );
}

export default function BlockSender({
    profiles,
    colorFillHex = "#8E89A8",
    senderBlocked = false,
    blockedByEmailZap = false,
    postUpdateTraining,
}) {
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const isZappedLabelEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.ZAPPED_LABEL);
    let blockedLabel = "black_list";
    if (isZappedLabelEnabled) {
        blockedLabel = "zapped";
    }
    const toggleSenderBlocking = async (profiles: ISenderProfile[]) => {
        if (!mailBotEnabled) return;
        let trainingStatus: string;
        const wasBlockedByEmailZap = profiles[0].blocked_by_emailzap;
        if (wasBlockedByEmailZap) {
            trainingStatus = "white_list";
        } else if (profiles[0].user_training !== blockedLabel) {
            trainingStatus = blockedLabel;
        } else {
            trainingStatus = "";
        }
        try {
            await updateUserTrainingStatus(profiles, trainingStatus);
            postUpdateTraining({ trainingStatus, profiles });
        } catch (error) {
            console.error("Error updating user training status:", error);
        }
    };
    const BLOCKED_BY_EMAILZAP_TEXT = isZappedLabelEnabled ? "Zapped" : "Blocked by EmailZap";
    const BLOCKED_BY_YOU_TEXT = isZappedLabelEnabled ? "Zapped by you" : "Blocked by you";
    const UNDO_TEXT = isZappedLabelEnabled ? "Unzap" : "Unblock";
    if (mailBotEnabledPending) {
        return null;
    }
    return (
        <div className="flex flex-col md:flex-row items-center">
            <div className="flex flex-row items-center gap-[2vw] md:gap-[0.2vw]">
                <BlockIcon colorFillHex={colorFillHex} />
                <Tooltip
                    title={
                        senderBlocked && blockedByEmailZap
                            ? `All emails from this sender will be automatically moved to ${isZappedLabelEnabled ? "Zapped" : "Low priority"} label created by Emailzap`
                            : ""
                    }
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                >
                    <p
                        className={`text-[12px] md:text-[14px] font-[Cambay] font-medium underline`}
                        style={{
                            color: mailBotEnabled ? colorFillHex : "#AAAAAA",
                            cursor:
                                !senderBlocked && profiles.length > 0 && mailBotEnabled
                                    ? "pointer"
                                    : blockedByEmailZap
                                      ? "text"
                                      : "not-allowed",
                        }}
                        onClick={() => {
                            if (!senderBlocked && profiles.length > 0) {
                                toggleSenderBlocking(profiles);
                            }
                        }}
                    >
                        {senderBlocked ? (blockedByEmailZap ? BLOCKED_BY_EMAILZAP_TEXT : BLOCKED_BY_YOU_TEXT) : "Block"}
                    </p>
                </Tooltip>
            </div>
            {senderBlocked && (
                <div className="md:ml-auto" onClick={() => toggleSenderBlocking(profiles)}>
                    <p
                        className="text-[12px] md:text-[14px] font-[Cambay] mt-[0.7vh] underline"
                        style={{
                            color: mailBotEnabled ? "#6E57EE" : "#AAAAAA",
                            cursor: mailBotEnabled ? "pointer" : "not-allowed",
                        }}
                    >
                        {UNDO_TEXT}
                    </p>
                </div>
            )}
        </div>
    );
}
