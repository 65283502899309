import { backendServerAxiosInstance } from ".";
import { ISubscription } from "../types/maintypes";

export const getLatestSubscription: () => Promise<ISubscription> = async () => {
    const response = await backendServerAxiosInstance.get("/payments/subscriptions/latest/");
    if (response.status === 204) {
        return null;
    }
    return response.data;
};

export const getPricings = async () => {
    const response = await backendServerAxiosInstance.get(`/payments/prices/`);
    return response.data;
};

export const cancelSubscription = async ({ id, reason, reasonText }) => {
    const response = await backendServerAxiosInstance.post(`/payments/subscriptions/${id}/cancel/`, {
        reason,
        reason_text: reasonText,
    });
    return response.data;
};

export const renewSubscription = async (id: string) => {
    const response = await backendServerAxiosInstance.post(`/payments/subscriptions/${id}/renew/`);
    return response.data;
};

export const getInvoices = async () => {
    const response = await backendServerAxiosInstance.get(`/payments/invoices/`);
    return response.data;
};

export const upgradeSubscription = async ({ id, newPriceId }) => {
    const response = await backendServerAxiosInstance.post(`/payments/subscriptions/${id}/upgrade/`, {
        price_id: newPriceId,
    });
    return response.data;
};
