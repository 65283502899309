const Slide4 = ({ handleOpenEmailzapApp, handleGoToInbox }) => {
    return (
        <div className="flex md:-ml-16  mt-5 justify-center items-center sm:flex-col flex-row text-center mb-2 ">
            <button
                type="button"
                className="font-inter inline-flex -ml-4 sm:-ml-0 text-[15px] sm:text-[16px] text-sm py-3 w-40 justify-center rounded-full bg-[#6E57EE] px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-[#6E57EE]-500 "
                onClick={handleGoToInbox}
            >
                Open Inbox
            </button>
            <p
                className="font-inter font-bold sm:mt-4 text-[12px] sm:text-[13px] hover:cursor-pointer -mt-1 ml-4 mr-1 sm:-ml-0 relative text-[#515461]"
                onClick={handleOpenEmailzapApp}
            >
                Open EmailZap App
                <span className="absolute bottom-0 left-0 w-full h-[1px] bg-[#515461]"></span>
            </p>
        </div>
    );
};

export default Slide4;
