import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
    getPricings,
    getInvoices,
    getLatestSubscription,
    renewSubscription,
    cancelSubscription,
    upgradeSubscription,
} from "../../services/payments";

export function usePricings() {
    return useQuery({
        queryKey: ["pricings"],
        queryFn: getPricings,
    });
}

export function useInvoices() {
    return useQuery({
        queryKey: ["invoices"],
        queryFn: getInvoices,
    });
}

export function useLatestSubscription() {
    return useQuery({
        queryKey: ["latest_subscription"],
        queryFn: getLatestSubscription,
    });
}

export function useRenewSubscription() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: string) => renewSubscription(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["latest_subscription"] });
            queryClient.invalidateQueries({ queryKey: ["mailbot"] });
        },
    });
}

export function useCancelSubscription() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: cancelSubscription,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["latest_subscription"] });
            queryClient.invalidateQueries({ queryKey: ["mailbot"] });
        },
    });
}

export function useUpgradeSubscription() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: upgradeSubscription,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["latest_subscription"] });
            queryClient.invalidateQueries({ queryKey: ["mailbot"] });
        },
    });
}
