import { useMailBotMetadata } from "hooks/api/mailbot";
import { useLatestSubscription, usePricings } from "hooks/api/payments";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createCheckoutSession } from "services/applications";
import Loader from "toolkit/Loader";
import { myContext } from "../../Context";

const StripeCheckout = () => {
    const { setShowLoader } = useContext(myContext) as any;
    const location = useLocation();
    const navigate = useNavigate();
    const { data: pricings, isPending: pricesPending } = usePricings();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    if (!pricesPending && !subscriptionPending && !mailbotMetadataPending) {
        let areAllScopesGranted = true;
        if (!mailbotMetadataPending && mailbotMetadata.all_scopes_granted === false) {
            areAllScopesGranted = false;
        }
        const queryParams = new URLSearchParams(location.search);
        const redirectToStripeCheckout = async (priceId: string, useCoupon: boolean) => {
            setShowLoader(true);
            try {
                const session = await createCheckoutSession({ price: priceId, use_coupon: useCoupon }, queryParams);
                window.location = session.redirect_url;
            } catch (error) {
                console.error("Error creating checkout session:", error);
            } finally {
                setShowLoader(false);
            }
        };
        const pricingNickname = queryParams.get("stripe_payment_plan") || "";
        const mailbotProfileCreated = queryParams.get("mailbot_profile_created") === "True";
        const useCoupon = queryParams.get("use_coupon") === "true";
        const price = pricings.find((pricing) => pricing.nickname === pricingNickname);
        if (mailbotProfileCreated && areAllScopesGranted && !subscription && price) {
            redirectToStripeCheckout(price.id, useCoupon);
        } else {
            navigate("/mail-bot");
        }
    }
    return <Loader />;
};

export default StripeCheckout;
