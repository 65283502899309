import { useInvoices, useLatestSubscription, useRenewSubscription } from "hooks/api/payments";
import { useState } from "react";
import Loader from "toolkit/Loader";
import Toast from "../../toolkit/Toast";
import Cancellation from "./Cancellation";
import Invoice from "./Invoice";
import PricingCatalog from "./PricingCatalog";
import SubscriptionCard from "./SubscriptionCard";
import { SubscriptionStatus } from "../../constants";
import { useIsPrimaryProfile } from "hooks/api/mailbot";

export default function Subscription() {
    const { data: subscription, isPending } = useLatestSubscription();
    const renewSubscription = useRenewSubscription();
    const [showCancellation, setShowCancellation] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("");
    const [toastHeading, setToastHeading] = useState("");
    const [toastText, setToastText] = useState("");
    const { data: invoices, isPending: invoicesPending } = useInvoices();
    const { data: isPrimaryProfile, isPending: isPrimaryProfilePending } = useIsPrimaryProfile();

    // Handle renewal of subscription
    const handleRenew = () => {
        renewSubscription.mutate(subscription!.id, {
            onSuccess: () => {
                setToastHeading("Subscription renewed!");
                setToastText("Your subscription was successfully renewed.");
                setToastVariant("success");
                setShowToast(true);
            },
            onError: () => {
                setToastHeading("Subscription renewal failed!");
                setToastText("Your subscription has failed.");
                setToastVariant("failure");
                setShowToast(true);
            },
        });
    };

    if (isPending || invoicesPending || isPrimaryProfilePending) {
        return <Loader />;
    }

    return (
        <>
            <div className="p-0 sm-pl-5 py-3 mt-5">
                {subscription &&
                    subscription?.price?.nickname !== "free" &&
                    [SubscriptionStatus.STATUS_ACTIVE, SubscriptionStatus.STATUS_PAST_DUE].includes(
                        subscription.status as SubscriptionStatus,
                    ) && (
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 md:ml-3">
                            <SubscriptionCard
                                handleRenew={handleRenew}
                                handleCancel={() => setShowCancellation(true)}
                            />
                        </div>
                    )}
                {isPrimaryProfile && <PricingCatalog hasAnyInvoice={invoices.length > 0} />}
                {invoices.length !== 0 && <Invoice invoices={invoices} />}
                {
                    <Cancellation
                        subscription={subscription!}
                        open={showCancellation}
                        setOpen={setShowCancellation}
                        setShowToast={setShowToast}
                        setToastVariant={setToastVariant}
                        setToastHeading={setToastHeading}
                        setToastText={setToastText}
                    />
                }
                {showToast && (
                    <Toast
                        heading={toastHeading}
                        text={toastText}
                        show={showToast}
                        setShow={setShowToast}
                        notificationType={toastVariant}
                    />
                )}
            </div>
            {renewSubscription.isPending && <Loader />}
        </>
    );
}
