import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useMedia } from "react-use";
import Loader from "toolkit/Loader";
import { ISenderProfile } from "types/maintypes";
import { getDisplayNumber, truncateText } from "utils/formatter";
import Pagination from "../../../toolkit/Pagination";
import SearchBar from "../../../toolkit/SearchBar";
import EmailDomainImage from "./EmailDomainImage";
import { getSenderUnsubscribeDetails } from "services/mailbot";
import { useIsMailBotActive, useUnsubscribeSender } from "hooks/api/mailbot";
import ActivateMailBotButton from "../ActivateMailBotButton";

interface IColumn {
    key: string;
    heading: string;
    width: string;
}

const COLUMNS: IColumn[] = [
    { key: "sender_email", heading: "Sender's email", width: "w-[18.47vw]" },
    { key: "total_count", heading: "Emails received", width: "w-[12.36vw]" },
    { key: "read_fraction", heading: "Read by you (%)", width: "w-[12.36vw]" },
    { key: "total_mailing_lists", heading: "Mailing lists", width: "w-[6.36vw]" },
    { key: "unsubscribe", heading: "", width: "w-[6.36vw]" },
];

const UnsubscribeSenderProfile = ({ profile, postUnsubscribe }) => {
    const unsubscribeSenderMutation = useUnsubscribeSender();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const onUnsubscribeClick = (profile) => {
        if (mailBotEnabled && !profile.unsubscribed) {
            unsubscribeSenderMutation.mutate(
                { senderProfileId: profile.id },
                {
                    onSuccess: () => {
                        postUnsubscribe();
                    },
                },
            );
        }
    };
    let buttonColor = profile.unsubscribed ? "red" : "#6E57EE";
    let cursorStyle = profile.unsubscribed ? "not-allowed" : "pointer";
    if (!mailBotEnabled) {
        if (buttonColor !== "red") {
            buttonColor = "gray";
        }
        cursorStyle = "not-allowed";
    }
    if (mailBotEnabledPending || unsubscribeSenderMutation.isPending) {
        return <Loader fullScreen={false} size={6} />;
    }
    return (
        <div
            onClick={() => {
                onUnsubscribeClick(profile);
            }}
            className="text-[Inter] text-xs md:text-sm"
            style={{
                color: buttonColor,
                cursor: cursorStyle,
            }}
        >
            {profile.unsubscribed ? "Unsubscribed" : "Unsubscribe"}
        </div>
    );
};

const SenderProfilesList = ({ senderProfiles, renderItem }) => {
    return (
        <table className="table-auto divide-y divide-gray-200 shadow-none font-[Inter] w-full">
            <thead
                style={{
                    boxShadow: "0px 4px 0px 0px #E5E7EB",
                    background: "linear-gradient(180deg, #F5F6FF 0%, rgba(255, 255, 255, 0) 93.23%)",
                }}
                className="h-[4.63vh] md:h-[3.9vh]"
            >
                {/* Desktop header */}
                <tr className="text-xs text-[#6B7280] hidden md:table-row">
                    {COLUMNS.map((column, index) => (
                        <th
                            className={`text-left uppercase text-xs leading-4 font-medium tracking-wider uppercase px-[1.11vw] py-[2.34vh] ${column.width}`}
                        >
                            {column.heading}
                        </th>
                    ))}
                </tr>
                {/* Mobile header */}
                <tr className="text-xs text-[#6B7280] table-row md:hidden">
                    <th
                        className={`text-left uppercase text-xs leading-4 font-medium tracking-wider uppercase pr-[6.4vw] pl-[3.2vw] py-[1.15vh]`}
                    >
                        Email
                    </th>
                </tr>
            </thead>
            {/* Desktop view */}
            {senderProfiles.length > 0 && (
                <tbody className="bg-white hidden md:table-row-group">
                    {senderProfiles.map((profile, idx) => (
                        <tr
                            key={profile.id}
                            className={`hover:bg-gray-50 ${idx !== senderProfiles.length - 1 && "border-b"}`}
                        >
                            {COLUMNS.map((column) => (
                                <td
                                    className={`text-sm text-[#111827] h-[8.45vh] md:h-[7.12vh] px-[1.11vw] py-[2.34vh] ${column.width}`}
                                >
                                    {renderItem(profile, column.key)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            )}
            {/* Mobile view */}
            {senderProfiles.length > 0 && (
                <tbody className="bg-white md:hidden">
                    {senderProfiles.map((profile, idx) => (
                        <tr
                            key={profile.id}
                            className={`hover:bg-gray-50 ${idx !== senderProfiles.length - 1 && "border-b"}`}
                        >
                            <td className={`text-sm text-[#111827] h-[8.45vh] pr-[3.2vw] pl-[3.2vw] py-[1.85vh]`}>
                                <div className="flex flex-row items-center">
                                    <div className="w-[46.93vw] flex flex-col gap-[14px]">
                                        <div>{renderItem(profile, "sender_email", 18)}</div>
                                        <div className="flex flex-row items-center justify-between">
                                            {/* mail read and total */}
                                            <p className="text-[Inter] text-[#616265]">
                                                Mails: {renderItem(profile, "total_count")}
                                            </p>
                                            <p className="text-[Inter] text-[#616265]">
                                                Read: {renderItem(profile, "read_fraction")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ml-auto">
                                        {/* block and configure icon */}
                                        {renderItem(profile, "unsubscribe")}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            )}
        </table>
    );
};

const paginationLimit = 10;

function Unsubscriber() {
    const [unsubscribeProfiles, setUnsubscribeProfiles] = useState<ISenderProfile[]>([]);
    const [unsubscribeProfilesPending, setUnsubscribeProfilesPending] = useState(true);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [queryFilteredCount, setQueryFilteredCount] = useState(0);
    const [queryFilteredCurrentPage, setQueryFilteredCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    let totalPages = Math.ceil(count / paginationLimit);
    if (searchQuery !== "") {
        totalPages = Math.ceil(queryFilteredCount / paginationLimit);
    }
    const isMobile = useMedia("(max-width: 767px)");
    useEffect(() => {
        getSenderUnsubscribeDetails(currentPage, paginationLimit).then((profiles) => {
            setUnsubscribeProfiles(profiles.results);
            setCount(profiles.count);
            setUnsubscribeProfilesPending(false);
        });
    }, [currentPage]);

    useEffect(() => {
        if (searchQuery !== "") {
            getSenderUnsubscribeDetails(queryFilteredCurrentPage, paginationLimit, searchQuery).then((profiles) => {
                setUnsubscribeProfiles(profiles.results);
                setQueryFilteredCount(profiles.count);
            });
        }
    }, [queryFilteredCurrentPage]);

    const handleSearch = async (query) => {
        try {
            // Return first page of results
            const profiles = await getSenderUnsubscribeDetails(1, paginationLimit, query);
            setUnsubscribeProfiles(profiles.results);
            setQueryFilteredCount(profiles.count);
            setQueryFilteredCurrentPage(1);
        } catch (error) {
            console.error("Error fetching sender profiles:", error);
        }
    };

    const nextPage = () => {
        if (searchQuery !== "") {
            setQueryFilteredCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        } else {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        }
    };

    const prevPage = () => {
        if (searchQuery !== "") {
            setQueryFilteredCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        } else {
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        }
    };

    const renderItem = (profile: ISenderProfile, key: string, maxEmailLength = 50) => {
        switch (key) {
            case "sender_email":
                return (
                    <div className="flex flex-row items-center gap-[0.83vw]">
                        <EmailDomainImage
                            name={profile.sender_name}
                            email={profile.sender_email}
                            domain={profile.sender_domain}
                        />
                        <Tooltip
                            title={profile.sender_email.length > maxEmailLength ? profile.sender_email : ""}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                        >
                            <p>{truncateText(profile[key], maxEmailLength)}</p>
                        </Tooltip>
                    </div>
                );
            case "read_fraction":
                return (profile[key] * 100).toFixed(1) + "%";
            case "total_count":
                return getDisplayNumber(profile[key]);
            case "unsubscribe":
                return (
                    <UnsubscribeSenderProfile
                        profile={profile}
                        postUnsubscribe={() => {
                            const newProfiles = unsubscribeProfiles.map((p) => {
                                if (p.id === profile.id) {
                                    return { ...p, unsubscribed: true };
                                } else {
                                    return p;
                                }
                            });
                            setUnsubscribeProfiles(newProfiles);
                        }}
                    />
                );
            default:
                return profile[key];
        }
    };

    if (unsubscribeProfilesPending) {
        return <Loader />;
    }

    return (
        <div className="md:w-[77.8vw] w-[89.33vw] mt-4 ml-4">
            <ActivateMailBotButton />
            <div className="mt-[1.17vh] flex flex-row items-center">
                <SearchBar
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch("")}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
            </div>
            <div className="shadow w-full mt-[1.17vh]">
                <SenderProfilesList senderProfiles={unsubscribeProfiles} renderItem={renderItem} />
                {unsubscribeProfiles.length === 0 && (
                    <div className="flex p-2 justify-center items-center">No sender profiles found.</div>
                )}
            </div>
            {searchQuery === "" ? (
                <Pagination
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    count={count}
                    paginationLimit={paginationLimit}
                    nextPage={nextPage}
                    prevPage={prevPage}
                />
            ) : (
                <Pagination
                    totalPages={totalPages}
                    setCurrentPage={setQueryFilteredCurrentPage}
                    currentPage={queryFilteredCurrentPage}
                    count={queryFilteredCount}
                    paginationLimit={paginationLimit}
                    nextPage={nextPage}
                    prevPage={prevPage}
                />
            )}
        </div>
    );
}

export default Unsubscriber;
