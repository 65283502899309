import { createContext, useState } from "react";

export const myContext: any = createContext({});

export default function Context(props: any) {
    // TODO: Clean this later as loader and popups should be managed by the individual components
    const [showLoader, setShowLoader] = useState(false);
    const [showActivationMessage, setShowActivationMessage] = useState(false);
    const [showDeactivationMessage, setShowDeactivationMessage] = useState(false);

    return (
        <myContext.Provider
            value={{
                showLoader,
                setShowLoader,
                showActivationMessage,
                setShowActivationMessage,
                showDeactivationMessage,
                setShowDeactivationMessage,
            }}
        >
            {props.children}
        </myContext.Provider>
    );
}
