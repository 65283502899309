/**
 * Add commas to the count
 * Example: 1000000 -> 1,000,000
 * @param count
 */
export const getDisplayNumber = (count) => {
    if (count === undefined || count === null) {
        console.error("Count is undefined or null");
        return "0";
    }
    return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Format the string to show only the first maxLength characters
 * @param text Email to format
 * @param maxLength Maximum length of the email
 * @returns
 */
export const truncateText = (text: string, maxLength: number = 50) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return `${text.substring(0, maxLength)}...`;
    }
};

/**
 * Calculate initials of the name with maximum length of `maxLength`
 * Example: John Doe -> JD
 * @param name Sender name
 * @param maxLength Maximum length of the initials
 * @returns
 */
export const nameInitials = (name, maxLength = 1) => {
    return name
        .split(" ")
        .map((part) => part[0])
        .slice(0, maxLength)
        .join("")
        .toUpperCase();
};
