import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import EditWorkflow from "components/Jarvis/EditWorkflow";
import Jarvis from "components/Jarvis/Jarvis";
import WorkflowList from "components/Jarvis/WorkflowList";
import Login from "components/Login/Login";
import ListProfiles from "components/Profiles/Profiles";
import { getCookie } from "utils/cookies";
import Context from "./Context";
import PostHogPageviewTracker from "./PostHogPageviewTracker";
import HomePage from "./components/HomePage/HomePage";
import Workflow from "./components/Jarvis/Workflow";
import MailBot from "./components/MailBot/MailBot";
import StripeCheckout from "./components/MailBot/StripeCheckout";
import MailOperations from "./components/MailOperations/MailOperations";
import Completion from "./components/Subscription/Completion";
import { MAILBOT_FEATURE_FLAGS } from "./constants";
import Unsubscriber from "components/MailBot/SenderProfile/Unsubscriber";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

function AllRoutes() {
    const isEmailZapEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.EMAILZAP);
    const isSecondaryProfilesEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.SECONDARY_PROFILES);
    const isUnsubscriberEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.UNSUBSCRIBE);
    return (
        <Routes>
            <Route path="/mail-operations" element={<MailOperations />} />
            <Route path="/" element={<HomePage />}>
                {isEmailZapEnabled && (
                    <>
                        <Route path="mail-bot/*" element={<MailBot />} />
                        <Route path="/stripe-checkout" element={<StripeCheckout />} />
                        <Route path="/completion" element={<Completion />} />
                        {isSecondaryProfilesEnabled && <Route path="/profiles" element={<ListProfiles />} />}
                        <Route index element={<MailBot />} />
                        <Route path="/workflows" element={<Jarvis />}>
                            <Route path="create" element={<Workflow />} />
                            <Route path="list" element={<WorkflowList />} />
                            <Route path="edit" element={<EditWorkflow />} />
                        </Route>
                        {isUnsubscriberEnabled && <Route path="/unsubscriber" element={<Unsubscriber />} />}
                    </>
                )}
            </Route>
        </Routes>
    );
}

function DefaultRoutes() {
    return (
        <Routes>
            <Route path="/mail-operations" element={<MailOperations />} />
            <Route path="*" element={<Login />} />
        </Routes>
    );
}

export const AppRouter: React.FunctionComponent<{}> = () => {
    const service_provider = getCookie("service_provider");
    useEffect(() => {
        if (CURRENT_ENV === "prod") {
            ReactGA.initialize([
                {
                    trackingId: "G-6FTN7T6QW4",
                },
            ]);
        }
    }, []);
    return (
        <div>
            <Context>
                <BrowserRouter>
                    <PostHogPageviewTracker />
                    {service_provider !== null && <AllRoutes />}
                    {service_provider === null && <DefaultRoutes />}
                </BrowserRouter>
            </Context>
        </div>
    );
};
