import StarIcon from "images/tour/star.svg";
import GmailIcon from "images/gmail_logo.png";
import CheckBoxIcon from "images/tour/check_box.svg";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import ImportantLabelIcon from "images/tour/label_important.svg";

const Slide3 = () => {
    const EMAILS_LIST = [
        { time: "11:32 PM" },
        { time: "7:22 PM" },
        { time: "4:12 PM" },
        { time: "3:57 PM" },
        { time: "2:35 PM" },
        { time: "12:01 PM" },
        { time: "10:15 AM" },
    ];
    return (
        <div className="flex bg-white rounded-l-[8px] overflow-y-hidden sm:h-[340px] h-[240px]">
            <div className="flex-1 pr-5 py-4 relative">
                {/* Search Bar */}
                <div className="ml-5 relative mb-4">
                    <div className="flex items-center mb-4">
                        <img src={GmailIcon} alt="Gmail Logo" className="w-10 h-8 mr-4" />
                        <input
                            type="text"
                            className="w-full px-9 py-2 border border-[#F3F6FA] bg-[#F3F6FA] rounded-full text-black "
                            placeholder="label:--low-priority--"
                            disabled
                            autoFocus={false}
                        />
                        <button className="absolute left-14 ml-2 top-0 mt-3 mr-3" autoFocus={false}>
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
                        </button>
                    </div>
                </div>
                {/* Emails List */}
                <div className="relative">
                    {EMAILS_LIST.map((email, index) => (
                        <div className="flex w-full" key={index}>
                            <div className="flex items-center justify-between border-b border-gray-200 pb-1 w-full">
                                <div className="flex items-center space-x-2">
                                    <img src={CheckBoxIcon} alt="Label Icon" className="w-8 h-8 mr-1" />
                                    <img src={StarIcon} alt="Label Icon" className="w-5 h-5 mr-1" />
                                    <img src={ImportantLabelIcon} alt="Label Icon" className="w-5 h-5 mr-1" />
                                </div>
                                <div className="flex-grow ml-4">
                                    <div className="relative">
                                        <div
                                            className="w-1/4 bg-[#D0D5DC] absolute left-0  inline-block mr-2 rounded"
                                            style={{ height: "0.4rem" }}
                                        ></div>
                                        <div
                                            className="w-2/4 h-2 sm:ml-6 ml-2 absolute right-4 bg-[#D0D5DC] inline-block rounded"
                                            style={{ height: "0.4rem" }}
                                        ></div>
                                    </div>
                                </div>
                                <div className=" mr-2 hidden sm:inline-block text-sm font-bold text-[#D0D5DC]">
                                    {email.time}
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* White Overlay Over Email Body */}

                    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 "></div>
                </div>
                {/* Centered Square Box with Loader and Text */}

                <div className="absolute flex bg-[#E2E4F7] flex-col items-center px-4 justify-center top-[61%] sm:top-[53%] left-1/2 transform -translate-x-1/2 -translate-y-1/2  rounded-lg  shadow-white ">
                    <div className="spinner mt-6"></div>
                    <div className="text-center mb-8 mt-5 text-sm mt-3 text-bold">
                        <b>Moving emails...</b>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slide3;
