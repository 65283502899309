import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, InboxIcon, InboxStackIcon, SunIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MUIDialog from "@mui/material/Dialog";
import Tour from "components/MailBot/Tour";
import { useLogoutUser, useUser } from "hooks/api/accounts";
import {
    useDeactivateMailBotProfile,
    useDeleteUserMailBotProfile,
    useIsMailBotActive,
    useMailBotPreferences,
    useMailBotProfileId,
    useSendSupportEmail,
} from "hooks/api/mailbot";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";
import execfnlogo from "images/execfnwhitelogo2x.png";
import { Fragment, useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "toolkit/Loader";
import Notification from "toolkit/Notification";
import Toast from "toolkit/Toast";
import { myContext } from "../../Context";
import { MAILBOT_FEATURE_FLAGS } from "../../constants";
import chatLogo from "../../images/chat_icon.png";
import emailLogo from "../../images/email_logo.png";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

const ProfileSettingsMenu = ({ setSidebarOpen, setToShowDeleteUserModal, setToShowDeleteUserConfirmModal }) => {
    const navigate = useNavigate();
    const { data: user, isPending: userPending } = useUser();
    const secondaryProfilesEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.SECONDARY_PROFILES);
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const logoutUser = useLogoutUser();

    const handleLogout = async () => {
        logoutUser.mutate(undefined, {
            onSuccess: () => {
                window.location.href = "/";
            },
        });
    };

    if (userPending || mailBotEnabledPending) {
        return <Loader />;
    }

    return (
        <Menu as="div" className="relative inline-block text-left flex-1 space-y-1 px-2 py-2">
            <div>
                <Menu.Button className="group w-full rounded-md px-2 text-left text-sm font-medium text-[#6A748B] hover:bg-gray-100 focus:outline-none">
                    <span className="flex w-full items-center justify-between">
                        <span className="flex min-w-0 items-center justify-between space-x-3">
                            <UserIcon
                                className={classNames(
                                    "text-neutral-600 group-hover:text-neutral-700",
                                    "mr-3 flex-shrink-0 h-6 w-6",
                                )}
                                aria-hidden="true"
                            />
                            <span className="flex min-w-0 flex-1 flex-col">
                                <span className="truncate text-sm text-neutral-800" title={user.first_name}>
                                    {user.first_name?.length > 20
                                        ? `${user.first_name.substring(0, 20)}...`
                                        : user.first_name}
                                </span>
                                <span className="truncate text-sm text-neutral-600" title={user.email}>
                                    {user.email?.length > 20 ? `${user.email.substring(0, 20)}...` : user.email}
                                </span>
                            </span>
                        </span>
                        <ChevronUpDownIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />
                    </span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute left-0 right-0 bottom-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {secondaryProfilesEnabled && (
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                        "block px-4 py-2 text-sm cursor-pointer",
                                    )}
                                    onClick={() => {
                                        navigate("/profiles");
                                        setSidebarOpen(false);
                                    }}
                                >
                                    View profiles
                                </div>
                            )}
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        {({ active }) => (
                            <div
                                className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm cursor-pointer",
                                )}
                                onClick={() => {
                                    if (mailBotEnabled && CURRENT_ENV !== "prod") {
                                        // If mailbot is enabled, then show delete user confirm modal
                                        setToShowDeleteUserConfirmModal(true);
                                    } else {
                                        // If mailbot is not enabled, then directly show delete user modal
                                        setToShowDeleteUserModal(true);
                                    }
                                    setSidebarOpen(false);
                                }}
                            >
                                Delete User
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <div
                                className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "px-4 py-2 text-sm cursor-pointer",
                                )}
                                onClick={() => {
                                    handleLogout();
                                    navigate("/");
                                    setSidebarOpen(false);
                                }}
                            >
                                Logout
                            </div>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

const ContactSupport = ({
    toggleSupportMenu,
    fullScreen,
    closeSidebar,
    setToastHeading,
    setToastText,
    setToastVariant,
    setShowToast,
}) => {
    const [text, setText] = useState("");
    const sendSupportEmailMutation = useSendSupportEmail();
    const handleSendSupportEmail = () => {
        if (text.length > 0) {
            sendSupportEmailMutation.mutate(
                { text },
                {
                    onSuccess: () => {
                        toggleSupportMenu();
                        closeSidebar();
                        setToastHeading("Email sent!");
                        setToastText(
                            "Your query has been sent to our support team. You should hear back from them soon.",
                        );
                        setToastVariant("success");
                        setShowToast(true);
                    },
                    onError: () => {
                        toggleSupportMenu();
                        closeSidebar();
                        setToastHeading("Failed to send email!");
                        setToastText("Your query could not be sent to our support team. Please try after sometime.");
                        setToastVariant("failure");
                        setShowToast(true);
                    },
                },
            );
        }
    };
    return (
        <div className="w-full md:h-[67.72vh] md:w-[423px]">
            {/* Header */}
            <div className="bg-[#081028] w-full h-[8vh] p-4 flex flex-row justify-between items-center">
                <div>
                    <div className="flex flex-row gap-2 mb-2">
                        <img src={emailLogo} alt="Email Logo" />
                        <p
                            style={{
                                color: "white",
                                fontFamily: "Gotham Light",
                                fontSize: "20px",
                                lineHeight: "14px",
                                textAlign: "left",
                            }}
                        >
                            Email
                            <span
                                style={{
                                    color: "white",
                                    fontFamily: "Gotham",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    lineHeight: "14px",
                                    textAlign: "left",
                                }}
                            >
                                Zap
                            </span>
                        </p>
                    </div>
                    <div className="text-[#F6F6F68C]">We are here to help you</div>
                </div>
                <div onClick={toggleSupportMenu} className="cursor-pointer">
                    <XMarkIcon className="h-6 w-6 text-white" />
                </div>
            </div>
            {/* TextArea */}
            <textarea
                className="w-full"
                style={{
                    height: `${fullScreen ? "80vh" : "53.4vh"}`,
                    padding: "3.36vh 0.83vw 47.78vh 0.83vw",
                    gap: "30px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    background: "white",
                    resize: "none",
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                }}
                value={text}
                onChange={(e) => {
                    setText(e.target.value);
                }}
                placeholder="Add your message..."
                maxLength={1000}
            />
            <div
                className="w-11/12 mx-auto bg-[#081028] text-white rounded-[2px] cursor-pointer"
                style={{
                    padding: "12px",
                    borderRadius: "2px 0px 0px 0px",
                }}
                onClick={handleSendSupportEmail}
            >
                <p className="font-medium text-center font-[Inter]">Send message</p>
            </div>
            {sendSupportEmailMutation.isPending && <Loader />}
        </div>
    );
};

const ContactSupportMenu = ({
    fullScreen = false,
    closeSidebar,
    setToastHeading,
    setToastText,
    setToastVariant,
    setShowToast,
}) => {
    const [supportOpened, setSupportOpened] = useState(false);
    const toggleSupportMenu = () => {
        setSupportOpened((x) => !x);
    };
    return (
        <>
            <div
                className="flex flex-row max-h-[40px] ml-4 gap-[10px] items-center cursor-pointer hover:bg-gray-100"
                onClick={toggleSupportMenu}
            >
                <div
                    className="rounded-full"
                    style={{
                        background: "linear-gradient(128.49deg, #CB3CFF 19.86%, #7F25FB 68.34%)",
                        padding: "7px",
                        height: "32px",
                        width: "32px",
                    }}
                >
                    <img height={20} width={20} src={chatLogo} alt="Contact Support" />
                </div>
                <p className="text-[#322D2D]">Contact EmailZap</p>
            </div>
            <MUIDialog open={supportOpened} onClose={toggleSupportMenu} fullScreen={fullScreen}>
                <ContactSupport
                    toggleSupportMenu={toggleSupportMenu}
                    fullScreen={fullScreen}
                    closeSidebar={closeSidebar}
                    setToastHeading={setToastHeading}
                    setToastText={setToastText}
                    setToastVariant={setToastVariant}
                    setShowToast={setShowToast}
                />
            </MUIDialog>
        </>
    );
};

const SideBarMobile = ({
    sidebarOpen,
    setSidebarOpen,
    navigation,
    setToShowDeleteUserModal,
    setToShowDeleteUserConfirmModal,
    setToastHeading,
    setToastText,
    setToastVariant,
    setShowToast,
}) => {
    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-zinc-50 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <img className="h-8 w-auto" src={execfnlogo} alt="Your Company" />
                                </div>
                                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                    <nav className="space-y-1 px-2">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? "bg-[#FFFFFF] text-[#153138] shadow-sm"
                                                        : "text-[#6A748B] hover:bg-gray-100",
                                                    "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                                                )}
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current
                                                            ? "text-[#153138]"
                                                            : "text-[#6A748B] group-hover:text-[#6A748B]",
                                                        "mr-4 flex-shrink-0 h-6 w-6",
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        ))}
                                    </nav>
                                </div>
                                <ContactSupportMenu
                                    fullScreen={true}
                                    closeSidebar={() => {
                                        setSidebarOpen(false);
                                    }}
                                    setToastHeading={setToastHeading}
                                    setToastText={setToastText}
                                    setToastVariant={setToastVariant}
                                    setShowToast={setShowToast}
                                />
                                <div className="flex flex-shrink-0">
                                    <ProfileSettingsMenu
                                        setSidebarOpen={setSidebarOpen}
                                        setToShowDeleteUserModal={setToShowDeleteUserModal}
                                        setToShowDeleteUserConfirmModal={setToShowDeleteUserConfirmModal}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 flex place-content-between md:hidden">
                <button
                    type="button"
                    className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
        </>
    );
};

const SideBarDesktop = ({
    navigation,
    setSidebarOpen,
    setToShowDeleteUserModal,
    setToShowDeleteUserConfirmModal,
    setToastHeading,
    setToastText,
    setToastVariant,
    setShowToast,
}) => {
    return (
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col border-r border-[#EBEBF3]">
            <div className="flex min-h-0 flex-1 flex-col bg-zinc-50">
                <div className="flex h-16 flex-shrink-0 items-center px-4">
                    <img className="h-8 w-auto" src={execfnlogo} alt="Your Company" />
                </div>
                <div className="flex flex-1 flex-col overflow-y-auto">
                    <nav className="flex-1 space-y-1 px-2 py-4">
                        {navigation.map((item) => (
                            <Link
                                key={item.name}
                                to={item.href}
                                className={classNames(
                                    item.current
                                        ? "bg-[#FFFFFF] text-[#153138] shadow-sm"
                                        : "text-[#6A748B] hover:bg-gray-100",
                                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                                )}
                            >
                                <item.icon
                                    className={classNames(
                                        item.current ? "text-[#153138]" : "text-[#6A748B] group-hover:text-[#6A748B]",
                                        "mr-3 flex-shrink-0 h-6 w-6",
                                    )}
                                    aria-hidden="true"
                                />
                                {item.name}
                            </Link>
                        ))}
                    </nav>
                </div>
                <ContactSupportMenu
                    closeSidebar={() => {
                        setSidebarOpen(false);
                    }}
                    setToastHeading={setToastHeading}
                    setToastText={setToastText}
                    setToastVariant={setToastVariant}
                    setShowToast={setShowToast}
                />
                <div className="flex flex-shrink-0">
                    <ProfileSettingsMenu
                        setSidebarOpen={setSidebarOpen}
                        setToShowDeleteUserModal={setToShowDeleteUserModal}
                        setToShowDeleteUserConfirmModal={setToShowDeleteUserConfirmModal}
                    />
                </div>
            </div>
        </div>
    );
};

export default function SideBar() {
    const { setShowDeactivationMessage } = useContext(myContext) as any;
    const { data: mailbotPreferences, isPending: mailbotPreferencesPending } = useMailBotPreferences();
    const { data: mailbotProfileId, isPending: mailbotProfileIdPending } = useMailBotProfileId();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const isEmailZapEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.EMAILZAP);
    const isJarvisEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.JARVIS);
    const isUnsubscriberEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.UNSUBSCRIBE);
    const cancelDeleteUserConfirmModalRef = useRef<any>(null);
    const cancelDeleteUserModalRef = useRef(null);
    // Whether to show tour popup
    const [toShowTourPopup, setToShowTourPopup] = useState(false);
    // Whether to show delete popup
    const [toShowDeleteUserModal, setToShowDeleteUserModal] = useState(false);
    const [toShowDeleteUserConfirmModal, setToShowDeleteUserConfirmModal] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("");
    const [toastHeading, setToastHeading] = useState("");
    const [toastText, setToastText] = useState("");
    const location = useLocation();
    const deleteUserMailBotProfile = useDeleteUserMailBotProfile();
    const deactivateMailBotProfile = useDeactivateMailBotProfile();

    const deactivateHandler = () => {
        deactivateMailBotProfile.mutate(mailbotPreferences, {
            onSuccess: () => {
                setShowDeactivationMessage(true);
            },
        });
    };

    const onDeleteUserMailBotProfile = () => {
        deleteUserMailBotProfile.mutate(mailbotProfileId, {
            onSuccess: () => {
                window.location.href = "/";
            },
            onError: () => {
                setToastHeading("Failed to delete user!");
                setToastText("Your user could not be deleted. Please try again after sometime.");
                setToastVariant("failure");
                setShowToast(true);
            },
        });
    };

    if (
        mailbotPreferencesPending ||
        mailbotProfileIdPending ||
        deactivateMailBotProfile.isPending ||
        deleteUserMailBotProfile.isPending
    ) {
        return <Loader />;
    }
    let navigation = [] as any;
    const queryParams = new URLSearchParams(location.search);
    const showOnboardingTour = queryParams.get("show_onboarding_tour");
    if (showOnboardingTour && showOnboardingTour === "True") {
        if (!toShowTourPopup) {
            setToShowTourPopup(true);
        }
    } else if (toShowTourPopup) {
        setToShowTourPopup(false);
    }
    if (isEmailZapEnabled) {
        navigation.push({
            name: "EmailZap",
            href: "/mail-bot",
            icon: InboxIcon,
            current: location.pathname.startsWith("/mail-bot") || location.pathname === "/",
        });
    }

    if (isJarvisEnabled) {
        navigation.push({
            name: "Jarvis",
            href: "/workflows",
            icon: SunIcon,
            current: location.pathname.startsWith("/workflows"),
        });
    }

    if (isUnsubscriberEnabled) {
        navigation.push({
            name: "Unsubscriber",
            href: "/unsubscriber",
            icon: InboxStackIcon,
            current: location.pathname.startsWith("/unsubscriber"),
        });
    }

    return (
        <>
            <SideBarMobile
                setSidebarOpen={setSidebarOpen}
                sidebarOpen={sidebarOpen}
                navigation={navigation}
                setToShowDeleteUserModal={setToShowDeleteUserModal}
                setToShowDeleteUserConfirmModal={setToShowDeleteUserConfirmModal}
                setToastHeading={setToastHeading}
                setToastText={setToastText}
                setToastVariant={setToastVariant}
                setShowToast={setShowToast}
            />
            <SideBarDesktop
                navigation={navigation}
                setSidebarOpen={setSidebarOpen}
                setToShowDeleteUserModal={setToShowDeleteUserModal}
                setToShowDeleteUserConfirmModal={setToShowDeleteUserConfirmModal}
                setToastHeading={setToastHeading}
                setToastText={setToastText}
                setToastVariant={setToastVariant}
                setShowToast={setShowToast}
            />
            {toShowTourPopup && <Tour setToShowPopup={setToShowTourPopup} />}
            {toShowDeleteUserConfirmModal && (
                <Notification
                    title={"Do you want to delete or deactivate?"}
                    text={
                        "Deactivating will pause EmailZap's categorisation and all the emails will start flowing back to your inbox. Deleting will also permanently delete all the trainings that you might have provided."
                    }
                    isSuccessNotification={false}
                    open={toShowDeleteUserConfirmModal}
                    initialFocusRef={cancelDeleteUserConfirmModalRef}
                    onCloseHandler={setToShowDeleteUserConfirmModal}
                >
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-black focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-indigo-600 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => {
                                setToShowDeleteUserConfirmModal(false);
                                setToShowDeleteUserModal(true);
                            }}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-slate-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-indigo-600 sm:col-start-2"
                            onClick={() => {
                                setToShowDeleteUserConfirmModal(false);
                                deactivateHandler();
                            }}
                            ref={(node: any) => (cancelDeleteUserConfirmModalRef.current = node)}
                        >
                            Deactivate
                        </button>
                    </div>
                </Notification>
            )}
            {toShowDeleteUserModal && (
                <Notification
                    title={"Are you sure you want to delete?"}
                    text={
                        "Emailzap will stop categorising the emails that you receive. You will also lose all the trainings you provided to Emailzap."
                    }
                    isSuccessNotification={false}
                    open={toShowDeleteUserModal}
                    initialFocusRef={cancelDeleteUserModalRef}
                    onCloseHandler={setToShowDeleteUserModal}
                >
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-indigo-600 sm:col-start-2"
                            onClick={() => {
                                setToShowDeleteUserModal(false);
                                onDeleteUserMailBotProfile();
                            }}
                        >
                            Yes, confirm
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-indigo-600"
                            onClick={() => setToShowDeleteUserModal(false)}
                            ref={(node: any) => (cancelDeleteUserModalRef.current = node)}
                        >
                            No, cancel
                        </button>
                    </div>
                </Notification>
            )}
            {showToast && (
                <Toast
                    heading={toastHeading}
                    text={toastText}
                    show={showToast}
                    setShow={setShowToast}
                    notificationType={toastVariant}
                    centralize={true}
                />
            )}
        </>
    );
}
