import LabelIcon from "images/tour/label.svg";
import GmailIcon from "images/gmail_logo.png";
import ArrowIcon from "images/tour/arrow.svg";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";
import { MAILBOT_FEATURE_FLAGS } from "../../../constants";

const Slide1 = () => {
    const isZappedLabelEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.ZAPPED_LABEL);
    return (
        <div className="flex rounded-l-[10px] mt-[0px] overflow-y-hidden bg-white sm:h-[340px] h-[260px]">
            {/* Left Sidebar (30%) */}
            <div className="bg-gray-100 rounded-l-[8px] w-3/8  p-4">
                <div className="flex items-center mb-4">
                    <img src={GmailIcon} alt="Gmail Logo" className="w-10 h-8 mr-2" />
                </div>
                <div className="mb-4">
                    <div className="w-[30%] h-2 bg-gray-200 mb-2"></div>
                    <div className="w-[35%] h-2 bg-gray-200 mb-2"></div>
                    <div className="w-[20%] h-2 bg-gray-200 mb-2"></div>
                    <div className="w-[35%] h-2 bg-gray-200 mb-2"></div>
                    <div className="w-[30%] h-4 bg-gray-200 mb-2"></div>
                </div>
                {/* Labels */}
                <div>
                    <p className="text-black-700 font-bold mb-2 font-inter">Labels</p>
                    <small className="text-gray-600 flex items-center mb-1 ">
                        <img src={LabelIcon} alt="Label Icon" className="w-4 h-4 mr-1" />
                        {isZappedLabelEnabled ? "Zapped" : "--- Low priority ---"}
                    </small>
                    <small className="text-gray-600 flex items-center">
                        <img src={LabelIcon} alt="Label Icon" className="w-4 h-4 mr-1" />
                        {isZappedLabelEnabled ? "Archiving_EmailZap" : "--- Junk ---"}
                    </small>
                </div>
            </div>

            {/* Right Sidebar (70%) */}
            <div className="flex-1 py-8 pl-8">
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[78%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[85%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[98%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[77%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[83%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[89%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[74%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[79%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[90%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[68%]"></div>
                <div className="h-2 bg-gray-200 mb-3 w-full sm:w-[85%]"></div>
                <div className="h-2 hidden sm:inline-block bg-gray-200 mb-3 w-full sm:w-[90%]"></div>
                <div className="h-2 hidden sm:inline-block bg-gray-200 mb-3 w-full sm:w-[95%]"></div>
            </div>
            {/* Render Arrow Icon above Label */}
            <div className="absolute flex flex-col items-center px-4 justify-center top-[83%] left-[178px] sm:top-[71%] sm:left-[197px] transform -translate-x-1/2 -translate-y-1/2 ">
                <img src={ArrowIcon} alt="Arrow" className="w-4 h-4 mr-2" />
            </div>
        </div>
    );
};

export default Slide1;
