import { useUser } from "./api/accounts";
import { APPLICATION_TAGS } from "../constants";
import { useFeatureFlags } from "./api/applications";

export function useIsUserLoggedIn() {
    const { data, isPending, error } = useUser();
    return !isPending && !error && !!data;
}

export function useIsMailBotFeatureEnabled(feature: string) {
    const authed = useIsUserLoggedIn();
    const { data: featureFlags, isPending } = useFeatureFlags(authed);
    if (isPending) {
        return false;
    }
    if (featureFlags[APPLICATION_TAGS.EMAILZAP] && featureFlags[APPLICATION_TAGS.EMAILZAP][feature]) {
        return true;
    } else {
        return false;
    }
}
