import SideBar from "components/SideBar/Sidebar";
import { useOnlineStatus } from "hooks/useOnlineStatus";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { Outlet, useLocation } from "react-router-dom";
import Notification from "toolkit/Notification";
import { updateCookies } from "../../utils/cookies";

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

function Homepage() {
    const location = useLocation();
    useEffect(() => {
        if (CURRENT_ENV === "prod") {
            ReactGA.pageview(location.pathname + location.search);
        }
        const search = location.search;
        const params = new URLSearchParams(search);
        const profileInUrl = params.get("profile");
        if (profileInUrl) {
            updateCookies("profile", profileInUrl);
        }
    }, [location]);
    const isOnline = useOnlineStatus();
    if (!isOnline) {
        return (
            <Notification
                isSuccessNotification={false}
                text="You cannot use EmailZap while offline. Please check your internet connection and try again."
                title="Offline"
                open={true}
                onCloseHandler={() => {}}
                initialFocusRef={null}
            />
        );
    } else {
        return (
            <>
                <SideBar />
                <div className="flex flex-col md:pl-64">
                    <main className="flex-1">
                        <div className="py-6">
                            <div>{<Outlet />}</div>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

export default Homepage;
