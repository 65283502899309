import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

function CurrentPageInfo({ count, paginationLimit, currentPage }) {
    return (
        <p className="text-[12px] md:text-sm text-gray-700">
            Showing <span className="font-medium">{count === 0 ? 0 : paginationLimit * (currentPage - 1) + 1}</span> to{" "}
            <span className="font-medium">{Math.min(paginationLimit * currentPage, count)}</span> of{" "}
            <span className="font-medium">{count}</span> results
        </p>
    );
}

const PaginationNavigation = ({ currentPage, totalPages, setCurrentPage, nextPage, prevPage, radius = 2 }) => {
    const pageNumbersToShow = Array.from({ length: totalPages }, (_, i) => i + 1).filter(
        (page) => page === 1 || page === totalPages || (page >= currentPage - radius && page <= currentPage + radius),
    );
    return (
        <div className="flex flex-row items-center justify-center">
            <button onClick={prevPage} disabled={currentPage === 1}>
                <ChevronLeftIcon className="md:h-[2.95vh] md:w-[1.38vw] h-[2.57vh] w-[5.66vw]" fill="#6E57EE" />
            </button>
            {/* Radius number of pages on both side of current page */}
            {pageNumbersToShow.map((page) => (
                <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={`mx-1 rounded-md md:w-[1.5vw] md:h-[1.5vw] w-[5.66vw] h-[2.57vh]`}
                    style={{
                        background:
                            currentPage === page ? "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)" : "",
                        borderRadius: "7px",
                    }}
                >
                    <p className="text-[#111827] text-[12px] md:text-[16px] font-[Inter] font-[500]">{page}</p>
                </button>
            ))}
            <button onClick={nextPage} disabled={currentPage === totalPages}>
                <ChevronRightIcon className="md:h-[2.95vh] md:w-[1.38vw] h-[2.57vh] w-[5.66vw]" fill="#6E57EE" />
            </button>
        </div>
    );
};

function Pagination(props) {
    const { totalPages, setCurrentPage, currentPage, count, paginationLimit, nextPage, prevPage } = props;

    return (
        <div className="flex flex-row items-center justify-between mt-[1.16vh]">
            <CurrentPageInfo count={count} paginationLimit={paginationLimit} currentPage={currentPage} />
            <PaginationNavigation
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                nextPage={nextPage}
                prevPage={prevPage}
            />
        </div>
    );
}

export default Pagination;
