import Backdrop from "@mui/material/Backdrop";
import { useState } from "react";
import { useMedia } from "react-use";
import { MAILBOT_FEATURE_FLAGS } from "../../../constants";
import maximize from "../../../images/maximize.png";
import minimize from "../../../images/minimize.png";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";

export default function InstructionsCard() {
    const isMobile = useMedia("(max-width: 767px)");
    const [collapsed, setCollapsed] = useState(true);
    const isZappedLabelEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.ZAPPED_LABEL);
    const Content = () => {
        return (
            <>
                <div
                    style={
                        !collapsed
                            ? {
                                  background:
                                      "linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%)",
                                  borderTopLeftRadius: "20px",
                                  borderTopRightRadius: "20px",
                              }
                            : {}
                    }
                    className={`flex flex-row justify-between items-center ${collapsed ? "" : "md:h-[3.9vh] h-[5.79vh]"}`}
                >
                    <p className="font-[Inter] text-[14px] text-white ml-[3vw] md:ml-[1.38vw]">EmailZap Instructions</p>
                    <div className="mr-[3.2vw] md:mr-[1.38vw] cursor-pointer">
                        {!collapsed && (
                            <img
                                className="w-[30px] h-[24px]"
                                src={minimize}
                                alt="minimize"
                                onClick={() => {
                                    setCollapsed(true);
                                }}
                            />
                        )}
                        {collapsed && (
                            <img
                                className="w-[14px] h-[14px]"
                                src={maximize}
                                alt="maximize"
                                onClick={() => {
                                    setCollapsed(false);
                                }}
                            />
                        )}
                    </div>
                </div>
                {!collapsed && (
                    <div className="ml-[3.2vw] md:ml-[1.38vw] mt-[1.17vh]">
                        <div>
                            <p className="font-[Inter] text-white text-[14px] w-[82.93vw] md:w-[73.7vw] leading-6">
                                Emailzap cleans your inbox without you having to provide any input. Every new email that
                                you get now will be checked by Emailzap’s algorithm to determine if it should land in
                                your inbox or not. However, if you want to define your own rule for a particular sender,
                                you can do so by selecting an option below.
                            </p>
                        </div>
                        <div
                            style={{
                                backgroundImage: "linear-gradient(to right,  #1445B4 33%, rgba(255,255,255,0) 0%)",
                                backgroundPosition: "bottom",
                                backgroundSize: "13px 3px",
                                backgroundRepeat: "repeat-x",
                                width: "75vw",
                                height: "1.17vh",
                                marginBottom: "1.17vh",
                            }}
                        ></div>
                        <div className="mb-[1.17vh]">
                            {/* Underline the text */}
                            <p className="font-[Inter] text-white text-[14px] font-medium underline underline-offset-4 leading-4 mb-[0.7vh]">
                                Block a sender
                            </p>
                            <p className="font-[Inter] text-white text-[12px] w-[81.86vw] md:w-[69.79vw]">
                                Blocking a sender will keep their emails out of your inbox. Those emails will still be
                                delivered to your mailbox but would automatically get delivered to the newly created
                                {isZappedLabelEnabled ? " Zapped" : " Junk"} label.
                            </p>
                        </div>
                        <div>
                            <p className="font-[Inter] text-white text-[14px] font-medium underline underline-offset-4 leading-4 mb-[0.7vh]">
                                Set a custom rule
                            </p>
                            <p className="font-[Inter] text-white text-[12px] w-[80vw] md:w-[69.79vw]">
                                You can define a custom rule to let us know how to treat emails from a sender in the
                                future
                            </p>
                        </div>
                    </div>
                )}
            </>
        );
    };
    if (isMobile && !collapsed) {
        return (
            <Backdrop open={true} className="z-10" onClick={() => setCollapsed(true)}>
                <div
                    className="rounded-[20px] absolute top-[20vh] pb-4 w-[89.33vw]"
                    style={{
                        background: "linear-gradient(107.38deg, #4F3AC0 2.61%, #6E57EE 101.2%)",
                    }}
                >
                    <Content />
                </div>
            </Backdrop>
        );
    }
    return (
        <div
            className={`flex flex-col justify-center rounded-[20px] ${collapsed ? "py-3" : "pb-4"}`}
            style={{
                background: collapsed
                    ? "linear-gradient(180deg, #6B54E9 0%, #533EC7 100%)"
                    : "linear-gradient(107.38deg, #4F3AC0 2.61%, #6E57EE 101.2%)",
            }}
        >
            <Content />
        </div>
    );
}
