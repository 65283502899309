import { getFeatureFlags } from "services/applications";
import { useQuery } from "@tanstack/react-query";

export function useFeatureFlags(enabled) {
    return useQuery({
        queryKey: ["feature-flags"],
        queryFn: getFeatureFlags,
        enabled: enabled,
    });
}
