import React from "react";

export default function Invoice({ invoices }) {
    return (
        <>
            <div className="font-semibold ml-3 mb-3 mt-5 text-[#374151]">Receipts</div>
            <div className="overflow-x-auto mx-2">
                <div className="rounded-lg border">
                    {invoices.length > 0 && (
                        <React.Fragment>
                            <table className={`min-w-full table-auto divide-y divide-gray-200 shadow-none`}>
                                <thead className="bg-gradient-to-b from-[#F5F6FF] via-white to-white pa-2">
                                    <tr className="text-xs text-[#6B7280] ">
                                        <th className="px-4 py-5 text-left">DATE</th>
                                        <th className="px-4 py-2 text-left">STATUS</th>
                                        <th className="px-4 py-2 text-left">INVOICE ID</th>
                                        <th className="px-4 py-2 text-left">TOTAL</th>
                                        <th className="px-4 py-2 text-left">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.map((invoice, index) => (
                                        <React.Fragment key={invoice.id}>
                                            <tr
                                                className={`hover:bg-gray-50 ${
                                                    index !== invoices.length - 1 && "border-b"
                                                }`}
                                            >
                                                <td className="px-4 py-2 text-sm">
                                                    <div>{new Date(invoice.created).toLocaleDateString("en-GB")}</div>
                                                </td>
                                                <td className="px-4 py-2 text-sm capitalize">{invoice.status}</td>
                                                <td className="px-4 py-2 text-sm">#{invoice.id}</td>
                                                <td className="px-4 py-2 text-sm">$ {invoice.amount_paid / 100}</td>
                                                <td className="px-4 py-2 text-sm border-l-0">
                                                    {invoice.hosted_invoice_url && (
                                                        <a
                                                            href={invoice.hosted_invoice_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="cursor-pointer text-center whitespace-nowrap font-bold text-[#7B61FF] py-1"
                                                        >
                                                            Download Receipt
                                                        </a>
                                                    )}
                                                </td>
                                            </tr>
                                            {index !== invoices.length - 1 && <tr className="border-b"></tr>}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                    )}
                    {invoices.length === 0 && <div className="p-2">No invoices found.</div>}
                </div>
            </div>
        </>
    );
}
