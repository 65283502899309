import "./App.css";

import { AppRouter } from "./Router";

function App() {
    // @ts-ignore
    return <AppRouter />;
}

export default App;
