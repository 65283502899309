import { Dialog, Transition } from "@headlessui/react";
import SuccessIcon from "images/tour/success.svg";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookies";
import Slider_1 from "./Slide_1";
import Slider_2 from "./Slide_2";
import Slider_3 from "./Slide_3";
import Slider_4 from "./Slide_4";
import "./styles.css";
import Loader from "../../../toolkit/Loader";
import { useUser } from "hooks/api/accounts";
import { MAILBOT_FEATURE_FLAGS } from "../../../constants";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";

const Tour = ({ setToShowPopup }) => {
    const THREE_SECOND_IN_MILLISECONDS = 3000;
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(true);
    const { data: user, isPending } = useUser();
    const [progressbar, setProgressbar] = useState(0);
    const service_provider = getCookie("service_provider");
    const isZappedLabelEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.ZAPPED_LABEL);

    const handleOpenEmailzapApp = () => {
        setOpen(false);
        setStep(0);
        setToShowPopup(false);
        navigate("/mail-bot");
    };

    const handleGoToInbox = () => {
        handleOpenEmailzapApp();
        let url = "";
        if (service_provider === "google") {
            url = `https://mail.google.com/mail/u/${user.email}`;
        } else if (service_provider === "microsoft") {
            // Outlook doesn't have a direct URL scheme like Gmail for accessing a specific email's inbox
            url = "https://outlook.live.com/mail/0/inbox";
        } else {
            throw new Error(`Unknown service provider: ${service_provider}`);
        }
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    /**
     * A React useEffect hook to animate a progress bar over time.
     */
    useEffect(() => {
        const interval = setInterval(() => {
            setProgressbar((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 1;
                } else {
                    clearInterval(interval);
                    return prevProgress;
                }
            });
        }, 90);
        return () => clearInterval(interval);
    }, []);

    /**
     * A React useEffect hook to increment the step value after a delay.
     */
    useEffect(() => {
        let interval: ReturnType<typeof setTimeout> | null = null;
        if (step < 3) {
            interval = setTimeout(() => {
                setStep((prevStep) => (prevStep + 1) % 5);
            }, THREE_SECOND_IN_MILLISECONDS);
        }

        return () => {
            if (interval) clearTimeout(interval);
        };
    }, [step]);
    const slide1Description = isZappedLabelEnabled
        ? "Creating Zapped and Archiving_EmailZap labels"
        : "Creating low priority and junk labels";
    const tourData = [
        {
            title: "Setup in progress..",
            description: slide1Description,
            slide: <Slider_1 />,
        },
        {
            title: "Setup in progress..",
            description: "Setting up a daily digest email for you",
            slide: <Slider_2 />,
        },
        {
            title: "Setup in progress..",
            description: "Moving last month’s unwanted emails to low priority and junk labels",
            slide: <Slider_3 />,
        },
        {
            title: "Successfully Setup",
            description:
                "EmailZap is now setup! You can go straight to your inbox and experience a cleaner inbox or go to EmailZap app to customise your EmailZap experience.",
            slide: <Slider_4 handleOpenEmailzapApp={handleOpenEmailzapApp} handleGoToInbox={handleGoToInbox} />,
        },
    ];

    if (isPending) {
        return <Loader />;
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10 font-inter" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="bg-gradient-to-b from-white via-[#E2E5FD] to-[#E2E5FD] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all lg:w-[655px] w-[350px] min-[2550px]:w-1/3 flex">
                                {
                                    <div className="pl-5 sm:pl-10 pb-4 sm:pt-8  flex flex-col grow ">
                                        <div className="pr-5 sm:pr-16">
                                            <div className="mt-7 sm:mt-9 w-full">
                                                {/* Slide Title */}
                                                <Dialog.Title
                                                    as="h3"
                                                    className="font-inter flex text-base mb-4 font-bold leading-6 text-[20px] sm:text-[24px] text-[#1F2337]"
                                                >
                                                    {step == 3 && (
                                                        <img
                                                            src={SuccessIcon}
                                                            alt="Gmail Logo"
                                                            className="w-6 h-6 mr-2"
                                                        />
                                                    )}
                                                    {tourData[step]["title"]}
                                                </Dialog.Title>
                                                {/* progress bar */}
                                                <div className="w-full bg-gray-200 rounded-full h-2">
                                                    <div
                                                        className={`bg-[#6E57EE] h-full rounded-full transition-all ease-in-out duration-1200`}
                                                        style={{ width: `${progressbar}%` }}
                                                    />
                                                </div>
                                                <div className="mt-4 sm:mt-5">
                                                    <p className="font-inter text-[14px] line-h-10 text-[#515461]">
                                                        {tourData[step]["description"]}
                                                    </p>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        {/* Slide screen */}
                                        <div className=" pl-0 pr-0 mt-0  mb-6 sm:mb-8">{tourData[step]["slide"]}</div>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Tour;
