import { useIsPrimaryProfile } from "hooks/api/mailbot";
import { useLatestSubscription, usePricings } from "hooks/api/payments";
import Loader from "toolkit/Loader";
import { SubscriptionStatus } from "../../constants";
import moment from "moment-timezone";

const SubscriptionCard = ({ handleRenew, handleCancel }) => {
    const { data: subscription, isPending } = useLatestSubscription();
    const { data: isPrimaryProfile, isPending: isPrimaryProfilePending } = useIsPrimaryProfile();
    const { data: pricings, isPending: isPricingsPending } = usePricings();
    if (isPrimaryProfilePending || isPending || isPricingsPending) {
        return <Loader />;
    } else if (!subscription) {
        return <div>No subscription found</div>;
    }
    let priceToShow = subscription.price;
    if (subscription?.phases?.length > 0) {
        const lastPhase = subscription.phases[subscription.phases.length - 1]["items"][0];
        priceToShow = pricings.find((pricing) => pricing.id === lastPhase.price);
    }
    const unitAmount = priceToShow.unit_amount;
    const intervalCount = priceToShow.interval_count;
    const interval = priceToShow.interval;
    const pricingNickname = priceToShow.nickname;
    let statusMessage = "";
    let statusClass = "";
    let daysRemainingMessage = "";
    let buttonText = "";
    let buttonColor = "";
    let buttonAction;
    if (subscription.expired) {
        statusMessage = "Subscription Expired";
        daysRemainingMessage = `Your last subscription was expired on ${new Date(
            subscription?.current_period_end,
        ).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
        })}`;
        statusClass = "bg-red-300 text-black-700";
    } else if (subscription.cancel_at_period_end || subscription.cancel_at) {
        statusMessage = "Subscription Cancelled";
        statusClass = "bg-red-300 text-black-700";
        daysRemainingMessage = `Subscription will expire on ${new Date(
            subscription?.current_period_end,
        ).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
        })}`;
        buttonText = "Renew subscription";
        buttonColor = "bg-green-600 text-white";
        buttonAction = handleRenew;
    } else if (SubscriptionStatus.STATUS_ACTIVE === subscription.status) {
        statusMessage = "Active plan";
        statusClass = "bg-[#D1FAE5] text-[#065F46]";
        daysRemainingMessage = `Next billing date on ${new Date(subscription?.current_period_end).toLocaleDateString(
            "en-GB",
            {
                day: "numeric",
                month: "long",
                year: "numeric",
            },
        )}`;
        buttonText = "Cancel Subscription";
        buttonColor = "bg-red-500 text-white hover:bg-red-400";
        buttonAction = handleCancel;
    } else if (SubscriptionStatus.STATUS_PAST_DUE === subscription.status) {
        statusMessage = "Payment overdue";
        statusClass = "bg-red-300 text-black-700";
        // We have configured grace period of 7 days from stripe subscription settings
        daysRemainingMessage = `You will loose access if not paid till ${moment(subscription?.current_period_start)
            .add(7, "days")
            .format("DD MMMM YYYY")}`;
    }
    if (!isPrimaryProfile) {
        buttonColor = "bg-gray-300 text-gray-500";
    }
    return (
        <div className="border p-5 border-solid border-#ebebf3 rounded-[32px] bg-gradient-to-b to-white from-[#FCEED3] mx-5 md:mx-0">
            <div className="font-bold">
                {pricingNickname !== "zapdeal" && (
                    <>
                        <span className="text-[44px] text-[#260651]">${unitAmount / 100} </span>
                        {intervalCount !== undefined && (
                            <span className="text-[#260651]">
                                {intervalCount === 1 ? `per ${interval}` : `every ${intervalCount} ${interval}s`}
                            </span>
                        )}
                    </>
                )}
                {pricingNickname === "zapdeal" && (
                    <div className="flex flex-col mb-2">
                        <span className="text-[44px] text-[#260651]">$1 / 90 days</span>
                        <span className="text-[#6B7280] ml-2">($5 per month afterwards)</span>
                    </div>
                )}
            </div>
            {statusMessage && (
                <div className={`inline-block ${statusClass} px-3 py-1 font-medium text-xs rounded-full`}>
                    {statusMessage}
                </div>
            )}
            {daysRemainingMessage && <div className="text-[#6B7280] ml-2 mt-3">{daysRemainingMessage}</div>}
            {buttonText && (
                <div className="mt-7 text-center">
                    <button
                        type="button"
                        className={`rounded-full px-10 py-2.5 text-sm font-semibold shadow-sm ${buttonColor}`}
                        onClick={buttonAction}
                        disabled={!isPrimaryProfile}
                    >
                        {buttonText}
                    </button>
                </div>
            )}
            {!isPrimaryProfile && (
                <div className="text-[#6B7280] ml-2 mt-3">
                    Please switch to primary profile for managing subscriptions.
                </div>
            )}
        </div>
    );
};

export default SubscriptionCard;
