import { backendServerAxiosInstance } from "services";

export const loginMailBotProfile = (serviceProvider: string, queryParams: Record<string, string> = {}) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join("&");
    if (serviceProvider === "google")
        window.open(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/mailbot/google-auth/login/?${queryString}`, "_self");
    else if (serviceProvider === "microsoft")
        window.open(
            `${process.env.REACT_APP_BACKEND_API_BASE_URL}/mailbot/microsoft-auth/login/?${queryString}`,
            "_self",
        );
};

export const getIsMailBotActive = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/mailbot-profile/is-active/`);
    return response.data;
};

export const getCanMailBotEnabled = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/mailbot-profile/can-enable/`);
    return response.data;
};

export const getCanMailBotDisabled = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/mailbot-profile/can-disable/`);
    return response.data;
};

export const getMailBotPreferences = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/preferences/");
    return response.data;
};

export const updateMailBotPreferences = async (preferences: any) => {
    const response = await backendServerAxiosInstance.put("/mailbot/preferences/", { preferences: preferences });
    return response.data;
};

export const deleteUserMailBotProfile = async (id) => {
    const response = await backendServerAxiosInstance.delete(`/mailbot/mailbot-profile/${id}/`);
    return response.data;
};

export const getMailBotSenderProfiles = async (currentPage: number, limit: number, search?: string) => {
    let url = `/mailbot/sender-profiles/?limit=${limit}&offset=${(currentPage - 1) * limit}`;
    if (search && search.trim() !== "") {
        url += `&search=${search}`;
    }
    const response = await backendServerAxiosInstance.get(url);
    return response.data;
};

export const updateUserTrainingStatus = async (profiles, trainingStatus) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-profiles/update-training/`, {
        sender_profiles: profiles,
        user_training: trainingStatus,
    });
    return response.data;
};

export const deactivateMailBotProfile = async (preferences) => {
    return updateMailBotPreferences({ ...preferences, mailbot_enabled: false });
};

export const activateMailBotProfile = async (preferences) => {
    return updateMailBotPreferences({ ...preferences, mailbot_enabled: true });
};

export const getMailSecondaryProfiles = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/secondary-profiles/");
    return response.data;
};

export const switchMailProfile = async (user_mailbot_profile_id) => {
    const response = await backendServerAxiosInstance.post("/mailbot/secondary-profiles/switch/", {
        user_profile_to_switch_id: user_mailbot_profile_id,
    });
    return response.data;
};

export const getStatistics = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/statistics/");
    return response.data;
};

export const configureSenderWorkflows = async (senderProfiles, action) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-workflow/`, {
        sender_profiles: senderProfiles,
        action: action,
    });
    return response.data;
};

export const deleteConfiguredSenderWorkflows = async (senderProfileIds) => {
    const response = await backendServerAxiosInstance.delete(`/mailbot/sender-workflow/${senderProfileIds}/`);
    return response.data;
};

export const sendSupportEmail = async ({ text }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/support/`, { body: text });
    return response.data;
};

export const deleteHistoricalEmails = async ({ profile }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-profiles/bulk-delete/`, {
        sender_profile: profile,
    });
    return response.data;
};

export const getSenderUnsubscribeDetails = async (currentPage: number, limit: number, search?: string) => {
    let url = `/mailbot/unsubscribe-details/?limit=${limit}&offset=${(currentPage - 1) * limit}`;
    if (search && search.trim() !== "") {
        url += `&search=${search}`;
    }
    const response = await backendServerAxiosInstance.get(url);
    return response.data;
};

export const unsubscribeSender = async ({ senderProfileId }) => {
    const response = await backendServerAxiosInstance.post(
        `/mailbot/unsubscribe-details/${senderProfileId}/unsubscribe/`,
    );
    return response.data;
};
